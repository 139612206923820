import React from 'react';
import LevelTwoCapability from '../level-two-capability';

import './index.css';
import { showCapabilityModal } from '../../actions/modals';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { IView, ViewType } from '../../interfaces';
import { IAppState } from '../../reducers';
import groupBy from 'lodash/groupBy';
import { makeGetCapabilityById, makeGetFilteredViews } from '../../utils/selectors';
import FormattedText from '../formatted-text';
import Tooltip from 'rc-tooltip';

interface Props {
    type: ViewType;
    l1Id: number;
    name: string;
    views: IView[];
}

export default function LevelOneEntity({ name, type, l1Id, views }: Props) {
    const dispatch = useDispatch();
    const state = useSelector((state: IAppState) => state);
    const getCapabilityById = makeGetCapabilityById();
    const capability = getCapabilityById(state, l1Id);

    const getFilteredViews = makeGetFilteredViews(views);
    const filteredViews = getFilteredViews(state);

    // https://circlesquare.dev/capability-tool/capability-tool-develop/-/issues/70
    // only display the L1 entity if there are any L2\L3 available
    if (!filteredViews.length) {
        return null;
    }

    if (!capability) {
        return <div>L1 entity not found</div>;
    }

    const onLevelOneClick = () => {
        if (type === ViewType.CAPABILITY) {
            dispatch(showCapabilityModal(capability));
        }
    };
    const capabilityClassNames = classnames('level-one-entity', {
        core: capability.type === 'Core',
    });

    const secondLevelGroup = groupBy(views, 'capID');

    let res: JSX.Element[] = [];

    for (let capID in secondLevelGroup) {
        const view = secondLevelGroup[capID][0];
        const getFilteredSecondLevelViews = makeGetFilteredViews(secondLevelGroup[capID]);
        const filteredSecondLevelViews = getFilteredSecondLevelViews(state);

        // https://circlesquare.dev/capability-tool/capability-tool-develop/-/issues/70
        // only display the L2 entity if there are any L3 available
        if (filteredSecondLevelViews.length) {
            res.push(
                <LevelTwoCapability
                    key={`${view.capID}_${view.capability}`}
                    type={view.capType}
                    capabilityID={view.capID}
                    name={view.capability}
                    views={secondLevelGroup[capID]}
                />,
            );
        }
    }

    return (
        <div className={capabilityClassNames}>
            <Tooltip
                trigger={'hover'}
                placement={'right'}
                mouseEnterDelay={1}
                destroyTooltipOnHide
                transitionName={'appear'}
                overlay={() => {
                    return capability.description ? (
                        <div className="label-item-popover">
                            <div className="label-description">
                                <FormattedText str={capability.description} />
                            </div>
                        </div>
                    ) : (
                        <div className="label-item-popover">
                            <div className="label-description">
                                <FormattedText str='No description' />
                            </div>
                        </div>
                    );
                }}
            >
                <div className="level-one-label_container">
                    <div className="level-one-header" onClick={onLevelOneClick}>
                        <h2>{name}</h2>
                    </div>
                </div>
            </Tooltip>
            {res}
        </div>
    );
}
