import React, { useState, useCallback, useEffect, ReactElement } from 'react';
import './index.css';
import Button from 'components/button';
import LabeledInput from 'components/labeled-input';
import DropDown from 'components/drop-down';
import { useDispatch, useSelector } from 'react-redux';
import { inviteUser, getCompaniesList } from 'actions/admin';
import {
    getGroups,
    getGroupsNames,
    makeGetUserInvitationForEmail,
    getCompaniesNames,
    getCompaniesListData,
    getUserAdminRoleStatus,
} from 'utils/selectors';
import { IAppState } from 'reducers';
import { getAllGroups } from 'actions/groups';
import { ActionStatus } from 'constants/action-statuses';
import { hide } from 'actions/modals';
import { showErrorToast } from 'components/toast-with-icon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

enum FailedValidationType {
    EMAIL_IS_EMPTY,
    WRONG_EMAIL_FORMAT,
    WRONG_COMPANY
}

export default function InviteUserModal() {
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [companyId, setCompanyId] = useState(0);
    const [groupId, setGroupId] = useState(0);
    const [failedValidation, setFailedValidation] = useState<FailedValidationType>()

    const state = useSelector((state: IAppState) => state);
    const isUserSuperAdmin = useSelector(getUserAdminRoleStatus);
    const groups = useSelector(getGroups);
    const groupsNames = useSelector(getGroupsNames);
    let companies = useSelector(getCompaniesListData);
    let companiesNames = useSelector(getCompaniesNames);
    if (!isUserSuperAdmin) {
        companies = [
            {
                id: state.user.companyId,
                name: state.user.companyName,
            },
        ];
        companiesNames = [state.user.companyName];
    }

    const selectedCompany = companies[companyId] || {};

    const getUserInvitationForEmail = useSelector(makeGetUserInvitationForEmail);
    const userInvitation = getUserInvitationForEmail(email);
    const userInvitationIsInProgress = userInvitation !== undefined && userInvitation.isInProgress;

    const dispatch = useDispatch();
    const validateEmail = (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const getEmailErrorMessage = (): string | boolean | ReactElement => {
        switch (failedValidation) {
            case FailedValidationType.EMAIL_IS_EMPTY:
                return (
                    <span>
                        <FontAwesomeIcon icon={faTimesCircle} /> Please Define User's Email
                    </span>
                );
            case FailedValidationType.WRONG_EMAIL_FORMAT:
                return (
                    <span>
                        <FontAwesomeIcon icon={faTimesCircle} /> The Email Address is improperly formatted.
                    </span>
                );
            default:
                return false;
        }
    };

    const onButtonClick = useCallback(() => {
        const newGroup = groups.list.find(({ name }) => name === groupsNames[groupId]);
        const company = companies.find(({ name }) => name === companiesNames[companyId]);

        if (!email) {
            setFailedValidation(FailedValidationType.EMAIL_IS_EMPTY);
            return;
        }
        if (!validateEmail(email)) {
            setFailedValidation(FailedValidationType.WRONG_EMAIL_FORMAT);
            return;
        }
        if (!companies.length) {
            showErrorToast('Please create a company first.')
        }
        if (!company) {
            setFailedValidation(FailedValidationType.WRONG_COMPANY);
            return;
        }
        dispatch(
            inviteUser({
                name: userName,
                email,
                groups: newGroup ? [newGroup.name] : [],
                company,
                callbackUrl: window.location.origin,
            }),
        );
        dispatch(hide());
    }, [
        companies,
        companiesNames,
        companyId,
        dispatch,
        email,
        groupId,
        groups.list,
        groupsNames,
        userName,
    ]);
    const onUserGroupUpdated = useCallback(id => {
        setGroupId(id);
    }, []);
    const onUserCompanyUpdated = useCallback(id => {
        setCompanyId(id);
    }, []);

    useEffect(() => {
        if (selectedCompany.id) {
            dispatch(getAllGroups(ActionStatus.STARTED, { companyId: selectedCompany.id }));
        }
        isUserSuperAdmin && dispatch(getCompaniesList({}));
    }, [dispatch, isUserSuperAdmin, selectedCompany.id]);

    return (
        <div id="invite-user-modal">
            <div className="invite-user-modal_overview-container">
                <h2 className="invite-user-modal_title">Invite user</h2>
                <div className="invite-user-modal_selectors-section">
                    <div className="invite-user-modal_selector-title">COMPANY</div>
                    <DropDown
                        selectionId={companyId}
                        selections={companiesNames}
                        label={companiesNames[companyId]}
                        onSelectionChanged={onUserCompanyUpdated}
                    />

                    <LabeledInput
                        label="EMAIL"
                        required
                        onChange={event => {
                            setEmail(event.currentTarget.value);
                        }}
                        error={getEmailErrorMessage()}
                    />

                    <LabeledInput
                        label="USER NAME"
                        onChange={event => {
                            setUserName(event.currentTarget.value);
                        }}
                    />

                    <div className="invite-user-modal_selector-title">GROUP</div>
                    <DropDown
                        selectionId={groupId}
                        selections={groupsNames}
                        label={groupsNames[groupId]}
                        onSelectionChanged={onUserGroupUpdated}
                    />
                </div>
                <div className="invite-user-modal_button">
                    <Button onClick={onButtonClick} primary disabled={userInvitationIsInProgress}>
                        Done
                    </Button>
                </div>
            </div>
        </div>
    );
}
