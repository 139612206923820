import React from 'react';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import './index.css';

interface Props {
    text: string;
    icon?: any;
}

export default function ToastWithIcon({ text, icon }: Props) {
    return (
        <div className='toast-wrapper'>
            <span className='toast-icon'>{icon}</span>
            <span>{text}</span>
        </div>
    );
}

export const ErrorToast = ({ text }) => <ToastWithIcon text={text} icon={<FontAwesomeIcon icon={faTimesCircle} /> } />
export const SuccessToast = ({ text }) => <ToastWithIcon text={text} icon={<FontAwesomeIcon icon={faCheckCircle} /> } />
export const InfoToast = ({ text }) => <ToastWithIcon text={text} icon={<FontAwesomeIcon icon={faInfoCircle} /> } />

export const showErrorToast = (text: string) => toast.error(<ErrorToast text={text} />, { autoClose: 2000 });
export const showSuccessToast = (text: string) => toast.success(<SuccessToast text={text} />, { autoClose: 2000 });
export const showInfoToast = (text: string) => toast.info(<InfoToast text={text} />, { autoClose: 2000 });