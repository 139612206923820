import { ADMIN } from '../constants/action-types';
import { adminService } from '../services';
import { IAdminAction, userInvited, inviteUser, updateUsersList, getUsersList, updateCompaniesList } from '../actions/admin';
import { ActionStatus } from '../constants/action-statuses';
import { getAllUsers, getUserGroups, getUserModels, getAllCompanies } from '../services/firebase/firestore';
import { appResetEverythingFile } from 'actions/auth';
import { showSuccessToast, showErrorToast } from 'components/toast-with-icon';

export default ({ dispatch }) => (next) => (action:IAdminAction) => {
	switch(action.type) {
		case ADMIN.INVITE_USER: {
			if(action.status.isStarted){		
				adminService.inviteUser(action.payload)
					.then(_ => {
						showSuccessToast(`User invite sent.`);

						dispatch(inviteUser(action.payload, ActionStatus.SUCCESS))
						dispatch(getUsersList({companyId:action.payload.company.id}))
					})
					.catch(err => {
						if(err.code === 'already-exists'){
							showErrorToast(`Email already in use.`);
						}else{
							showErrorToast(err.message);
						}
						dispatch(inviteUser(action.payload, ActionStatus.FAILED))
						dispatch(userInvited(ActionStatus.FAILED));
					});
			}
			break;
		}
		case ADMIN.RESEND_INVITE: {
			if(action.status.isStarted){		
				adminService.resendInvite(action.payload)
					.then(_ => {
						showSuccessToast(`User invite sent.`);
					})
					.catch(err => {
						showErrorToast(err.message);
					});
			}
			break;
		}
		case ADMIN.GET_USERS_LIST: {
			if(action.status.isStarted) {
				getAllUsers(action.payload.companyId)
					.then(allUsers => {
						return Promise.all(allUsers.map(async user => {
							const groups = await getUserGroups(action.payload.companyId, user.uid);
							const models = await getUserModels(action.payload.companyId, user.uid);
							return {...user, groups, models};
						}))})
					.then(_ => dispatch(updateUsersList(_ , ActionStatus.SUCCESS)))
					.catch(_ => dispatch(updateUsersList(_ , ActionStatus.FAILED)))
			}
			break;
		}
		case ADMIN.GET_COMPANIES_LIST: {
			if(action.status.isStarted) {
				getAllCompanies()
					.then(_ => dispatch(updateCompaniesList(_ , ActionStatus.SUCCESS)))
					.catch(_ => dispatch(updateCompaniesList(_ , ActionStatus.FAILED)))
			}
			break;
		}
		case ADMIN.ADD_COMPANY: {			
			adminService.addCompany(action.payload)
				.then(_ => {
					console.log(_);
					showSuccessToast(`Company added successfully.`);
				})
				.catch(_ => showErrorToast(_.message));
			break;
		}
		case ADMIN.ADD_LICENCE: {			
			adminService.addLicence(action.payload)
				.then(_ => {
					console.log(_);
					showSuccessToast(`Licence added successfully.`);
				})
				.catch(_ => showErrorToast(_.message));
			break;
		}
		case ADMIN.UPDATE_LICENCE: {			
			adminService.updateLicense(action.payload)
				.then(_ => {
					console.log(_);
					showSuccessToast(`Licence updated successfully.`);
				})
				.catch(_ => showErrorToast(_.message));
			break;
		}
		case ADMIN.DELETE_LICENCE: {			
			adminService.deleteLicence(action.payload.companyId, action.payload.id)
				.then(_ => {
					console.log(_);
					showSuccessToast(`Licence deleted successfully.`);
				})
				.catch(_ => showErrorToast(_.message));
			break;
		}
		case ADMIN.DISABLE_USER: {			
			adminService.disableUser(action.payload)
				.then(_ => {
					console.log(_);
					dispatch(getUsersList({companyId:action.payload.companyId}))
					showSuccessToast(`User ${action.payload.disabled ? 'disabled' : 'enabled'} successfully.`);
				})
				.catch(_ => showErrorToast(_.message));
			break;
		}
		case ADMIN.DELETE_USER: {			
			adminService.deleteUser(action.payload)
				.then(_ => {
					console.log(_);
					dispatch(getUsersList({companyId:action.payload.companyId}))
					showSuccessToast(`User deleted successfully.`);
				})
				.catch(_ => showErrorToast(_.message));
			break;
		}
		case ADMIN.UPDATE_USER: {
			adminService.updateUser(action.payload.uid, action.payload)
				.then(_ => {
					console.log(_);
					dispatch(appResetEverythingFile())
					dispatch(getUsersList({companyId:action.payload.companyId}))
					showSuccessToast(`User updated successfully.`);
				})
				.catch(_ => showErrorToast(_.message));
			break;
		}
		default:
			break;
	}
	return next(action);
};
