import { IRAWIconColors } from "../interfaces";

export interface IMetricIconFormattedCollors {
    backgroundColorAlpha: string;
    backgroundColor: string;
    borderLeft: string;
    borderRight: string;
    color: string;
}
export const buildColorStyles = (metric?: IRAWIconColors): IMetricIconFormattedCollors => {
    if (!metric) {
        return {} as any;
    }

    return {
        backgroundColorAlpha: `#${metric.fillColour}66`,
        backgroundColor: `#${metric.fillColour}`,
        borderLeft: `10px solid #${metric.fillColour}`,
        borderRight: `10px solid #${metric.fillColour}`,
        color: `#${metric.fontColour}`
    };
};

export const buildColorStylesItems = (metric?: IRAWIconColors): IMetricIconFormattedCollors => {
    if (!metric) {
        return {} as any;
    }

    return {
        backgroundColorAlpha: `#${metric.fillColour}66`,
        backgroundColor: `#fefefe`,
        borderLeft: `10px solid #${metric.fillColour}`,
        borderRight: `none`,
        color: `#000000`
    };
};

export const buildColorStylesItemsFull = (metric?: IRAWIconColors): IMetricIconFormattedCollors => {
    if (!metric) {
        return {} as any;
    }

    return {
        backgroundColorAlpha: `#${metric.fillColour}66`,
        backgroundColor: `#${metric.fillColour}`,
        borderLeft: `none`,
        borderRight: `none`,
        color: `#${metric.fontColour}`
    };
};

export const buildColorStylesItemsRight = (metric?: IRAWIconColors): IMetricIconFormattedCollors => {
    if (!metric) {
        return {} as any;
    }

    return {
        backgroundColorAlpha: `#${metric.fillColour}66`,
        backgroundColor: `#fefefe`,
        borderLeft: `none`,
        borderRight: `10px solid #${metric.fillColour}`,
        color: `#000000`
    };
};

export const formatCost = (value:number) => (value || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

// [1, 2, 3, 4, 5, 6] <-- source
// 2 -> 1, 3 -> 4, 5 -> 1, 6 // views
// step1: 1 -> 2, 5 , new source: [1, 3, 4, 6]
// step2: 3 -> 4, new source: [1, 3, 6]
// step3: 6, new source: [1, 3, 6]
// result:
// 1 -> 2, 5
// 3 -> 4
// 6
// Build capability tree withing given source recursively
export const buildCapabilityTree = () => {
    throw new Error('Not implemented');
};