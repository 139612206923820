import { useState, useMemo } from 'react';
import { sortBy } from 'lodash';

export enum SortableDataDirection {
    ASC,
    DESC
}

export interface ISortableConfig {
    key: string;
    direction: SortableDataDirection;
}

const useSortableData = <T>(items: T[], config: ISortableConfig = { key: 'name', direction: SortableDataDirection.ASC }) => {
    const [sortConfig, setSortConfig] = useState<ISortableConfig>(config!);

    const sortedItems = useMemo(() => {
        const sorted = sortBy(items, sortConfig.key);
            if (sortConfig.direction === SortableDataDirection.DESC) {
                return sorted.reverse();
            }

            return sorted;
    }, [items, sortConfig]);

    const requestSort = (key: string) => {
        let direction = SortableDataDirection.ASC;
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === SortableDataDirection.ASC
        ) {
            direction = SortableDataDirection.DESC;
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};

export default useSortableData;