import { LAYOUT } from "../constants/layout";
import { IAction } from "../reducers";


export interface IShowMetricBarPayload {
    isVisible: boolean
}

export interface IActionShowMetricBar extends IAction<typeof LAYOUT.SHOW_METRIC_BAR, IShowMetricBarPayload> { }

export const showMetricBar = (showMetricBar: IShowMetricBarPayload): IActionShowMetricBar => ({
    type: LAYOUT.SHOW_METRIC_BAR,
    payload: showMetricBar
})

export type ILayoutActions = IActionShowMetricBar;