import React, { useState, useCallback, useEffect } from 'react';

import './index.css';
import AppIcon from '../app-icon';
import { BaseDropDown } from '../base-drop-down';
import { useDispatch } from 'react-redux';
import { deleteLicence } from '../../actions/admin';
import { showEditLicenseModal } from '../../actions/modals';
import ConfirmationModal from 'components/confirmation-modal';
import UserLogo from 'components/user-logo';
import { dataService } from 'services';

interface Props {
    icon?: string;
    uid: string;
    name: string;
    companyId: string;
    companyName: string;
    users: string[];
    renewalDate?: Date;
}

export default function LicencesListItem({
    uid,
    name,
    companyId,
    companyName,
    users,
    renewalDate,
}: Props) {
    const [assignedModels, setAssignedModels] = useState(0);
    const [actionLabels] = useState(['Edit', 'Delete']);
    const [models, setModels] = useState([] as any[]);
    const [deleteLicenseVisible, setDeleteLicenseVisible] = useState(false);
    const dispatch = useDispatch();
    const hideDeleteModal = () => setDeleteLicenseVisible(false);
    const confirmLicenseDelete = () => {
        dispatch(deleteLicence({ companyId, id: uid }));
        hideDeleteModal();
    };
    const onMoreMenuItemClicked = useCallback(
        id => {
            switch (id) {
                case 0:
                    dispatch(showEditLicenseModal({ uid, companyId }));
                    break;
                case 1:
                    setDeleteLicenseVisible(true);
                    break;
                default:
            }
        },
        [dispatch, uid, companyId],
    );

    useEffect(() => {
        (async () => {
            const usersModels = await Promise.all(users.map(uid => dataService.getUserModels(companyId, uid)));
            setAssignedModels(usersModels.reduce((acc, curr) => {
                acc += curr.length
                return acc;
            }, 0));
        })()
    }, [companyId, users])

    useEffect(() => {
        (async () => {
            setModels(await dataService.getAllModels(companyId));
        })()
    }, [companyId])

    return (
        <>
            <div className="license-item_icon">
                <UserLogo name={name} email={name} />
            </div>
            <div className="license-item_name-container">
                <div className="license-item_name">{name}</div>
            </div>
            <div className="license-item_name-container">
                <div className="license-item_name">{companyName}</div>
            </div>
            <div className="license-item_users">
                {users.length} {users.length === 1 ? 'User' : 'Users'}
            </div>
            <div className="license-item_name">{models.length}</div>
            <div className="license-item_name">{assignedModels}</div>
            <div className="license-item_date">{renewalDate?.toLocaleDateString()}</div>
            <div className="license-item_action">
                <BaseDropDown
                    renderSelections={() =>
                        actionLabels.map((selection, key) => (
                            <div className="list-item" key={key} data-id={key}>
                                {selection}
                            </div>
                        ))
                    }
                    onSelectionChanged={onMoreMenuItemClicked}
                >
                    <AppIcon iconName="more" />
                </BaseDropDown>
            </div>
            {deleteLicenseVisible && (
                <ConfirmationModal
                    title="Are you sure ?"
                    message="Once this action is confirmed, it cannot be undone."
                    cancelLabel="Cancel"
                    okLabel="Delete"
                    onCancel={hideDeleteModal}
                    onConfirm={confirmLicenseDelete}
                />
            )}
        </>
    );
}
