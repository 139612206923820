import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom'; // react-router v4/v5
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import auth from 'pages/auth/auth';
import SignInPage from 'pages/sign-in/signIn';
import PasswordResetPage from 'pages/password-reset';
import DashboardPage from 'pages/dashboard';
import FileUpload from 'pages/fileUpload/fileUpload';
import AdminPage from 'pages/admin';
import Modal from 'components/general-modal';

import { appInit } from 'actions/auth';

import 'rc-tooltip/assets/bootstrap_white.css';
import './reset.css';
import 'App.css';
import { IAppState } from 'reducers';
import HelpPage from 'pages/helpPage';
import { getUserAdminStatus } from 'utils/selectors';
import { DataStore } from 'services/dataStoreInterfaces';

toast.configure();
export const DataContext = React.createContext({
    users: {},
    setUsers: (() => {}) as any,
    companies: {} as Record<string, DataStore.CompanyStoreDataWithId>,
    setCompanies: (() => {}) as React.Dispatch<
        React.SetStateAction<Record<string, DataStore.CompanyStoreDataWithId>>
    >,
    licences: [] as DataStore.LicenceStoreDataWithId[],
    setLicences: (() => {}) as React.Dispatch<
        React.SetStateAction<DataStore.LicenceStoreDataWithId[]>
    >,
});

function App() {
    const [licences, setLicences] = useState<DataStore.LicenceStoreDataWithId[]>([]);
    const [users, setUsers] = useState({});
    const [companies, setCompanies] = useState<Record<string, DataStore.CompanyStoreDataWithId>>(
        {},
    );
    const [contextValue, setContextValue] = useState({
        users,
        setUsers,
        licences,
        setLicences,
        companies,
        setCompanies,
    });

    useEffect(() => {
        setContextValue({
            users,
            setUsers,
            licences,
            setLicences,
            companies,
            setCompanies,
        });
    }, [companies, licences, users]);

    const isAppInitialized = useSelector(({ auth }: IAppState) => auth.isAppInitialized);
    const isLogged = useSelector(({ auth }: IAppState) => auth.isLogged);
    const hasUserAdminRole = useSelector(getUserAdminStatus);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(appInit());
    }, [dispatch]);

    if (!isAppInitialized) {
        return null;
    }
    const renderContent = () =>
        isLogged ? (
            <Switch>
                <Route path="/dashboard" component={DashboardPage} />
                <Route exact path="/upload" component={FileUpload} />
                <Route exact path="/help" component={HelpPage} />
                {hasUserAdminRole && <Route exact path="/admin" component={AdminPage} />}
                {hasUserAdminRole && <Redirect to="/admin" />}
                <Redirect to="/dashboard" />
            </Switch>
        ) : (
            <Switch>
                <Route exact path="/signin" component={auth(SignInPage)} />
                <Route exact path="/password-reset" component={auth(PasswordResetPage)} />
                <Redirect to="/signin" />
            </Switch>
        );

    return (
        <DataContext.Provider value={contextValue}>
            {renderContent()}
            <Modal />
        </DataContext.Provider>
    );
}

export default App;
