import {MODEL} from '../constants/action-types'
import { IAction } from '../reducers'

export interface IModelInfoPayload {
    name: string;
    supportEmail: string;
    reportDate: Date;
    group1Name: string;
    group2Name: string;
    group3Name: string;
}
export interface IUploadModelPayload {
    file: File;
}

export interface IActionUpdateModelInfo extends IAction<typeof MODEL.UPDATE_MODEL_INFO, IModelInfoPayload> { }
export interface IActionUploadModel extends IAction<typeof MODEL.UPLOAD_MODEL, IUploadModelPayload> { }

export const updateModelInfo = (payload: IModelInfoPayload) => ({
    type: MODEL.UPDATE_MODEL_INFO,
    payload
})

export const uploadModel = (payload: IUploadModelPayload) => ({
    type: MODEL.UPLOAD_MODEL,
    payload
})

export type IModelActions = IActionUpdateModelInfo;