import React from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';
import AppIcon from 'components/app-icon';
import Button from 'components/button';
import './index.css';

export interface IConfirmationModal {
    onCancel: () => void;
    onConfirm: () => void;
    title: string;
    message: string;
    cancelLabel: string;
    okLabel: string;
}

export default function ConfirmationModal({
    onCancel,
    onConfirm,
    title,
    message,
    cancelLabel,
    okLabel,
}: IConfirmationModal) {
    const appElement = document.getElementById('root');
    if (!appElement) {
        return null;
    }

    return (
        <ReactModal
            appElement={appElement}
            isOpen
            className={cx('confirmation-modal')}
            overlayClassName="modal-overlay"
            onRequestClose={onCancel}
        >
            <div id="confirmation-modal-container">
                <div className="close-button hoverable" onClick={onCancel}>
                    <AppIcon iconName="close" />
                </div>
                <h2>{title}</h2>
                <p>{message}</p>
                <div className="confirmation-buttons">
                    <Button onClick={onCancel} secondary>
                        {cancelLabel}
                    </Button>
                    <Button onClick={onConfirm} primary>
                        {okLabel}
                    </Button>
                </div>
            </div>
        </ReactModal>
    );
}