export enum IUserInviteStatus {
    PENDING_REQUEST = 'Pending request',
    COMPLETED = 'Completed',
    DISABLED = 'Disabled'
}

export enum IUserGroups {
    LPFM_ADMIN = 'LPFM admin',
    CLIENT_ADMIN = 'Client admin',
    EDITOR = 'Editor',
    APPROVER = 'Approver',
    READ_ONLY = 'Read only'
}

export declare namespace Firebase {
    export namespace Firestore {
        export namespace Company {
            export type Id = string;
            export type Name = string;
        }
        
        export namespace User {
            export type id = string;
            export type name = string;
            export type email = string;
            export type disabled = boolean;
            export type invitedBy = User.id;
            export type status = IUserInviteStatus;
        }
        
        export namespace Group {
            export type id = string;
            export type name = string;
            export type createdAt = firebase.firestore.Timestamp;
        }
        
        export namespace Licence {
            export type id = string;
            export type name = string;
            export type createdAt = firebase.firestore.Timestamp;
            export type updatedAt = firebase.firestore.Timestamp;
            export type renewAt = firebase.firestore.Timestamp;
        }

        export namespace Model {
            export type id = string;
            export type createdAt = firebase.firestore.Timestamp;
            export namespace uploadData {
                export type name = string;
                export type size = number;
                export type type = string;
                export type path = string;
                export type url = string;
                export type createdAt = firebase.firestore.Timestamp;
                export type updatedAt = firebase.firestore.Timestamp;
                export type uploadedAt = firebase.firestore.Timestamp;
            }
        }

        export interface UserStoreData {
            email: User.email;
            name: User.name;
            status: User.status;
            companyId: Company.Id;
            invitedBy: User.invitedBy;
            disabled: User.disabled;
            companyName: Company.Name;
        }
    
        export interface UserStoreDataWithId extends UserStoreData {
            uid: User.id;
        }
    
        export interface UserGroupStoreData {
            id: Group.id;
            name: Group.name;
            createdAt: Group.createdAt;
        }
        
        export interface LicenceStoreData {
            companyId: Company.Id;
            name: Group.name;
            users: User.id[];
            renewAt: Licence.renewAt;
            createdAt: Licence.createdAt;
        }
    
        export interface LicenceStoreDataWithId extends LicenceStoreData{
            id: Licence.id;
        }

        export interface ModelUploadDataStoreData {
            name: Model.uploadData.name;
            size: Model.uploadData.size;
            type: Model.uploadData.type;
            path: Model.uploadData.path;
            url: Model.uploadData.url;
            createdAt: Model.uploadData.createdAt;
            updatedAt: Model.uploadData.updatedAt;
        }
    
        export interface ModelMetaDataStoreData {
            uploadOwner: User.id;
            uploadedAt?: Model.uploadData.uploadedAt;
        }
        export interface ModelStoreData extends ModelUploadDataStoreData{
            id: Model.id;
            companyId: Company.Id;
            metadata: ModelMetaDataStoreData;
        }
    }
}
