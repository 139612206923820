import { AUTH } from '../constants/action-types'
import { ActionStatus } from '../constants/action-statuses'
import { IAuthAction } from '../actions/auth';

export interface IAuthState {
    email: string
    password: string
    userName: string
    companyName: string
    isLogged: boolean
    isSignInInProgress: boolean
    isAppInitialized: boolean
    authError: any    
}

export const defaultState: IAuthState = {
    email: '',
    password: '',
    userName: '',
    companyName: '',
    isLogged: false,
    isSignInInProgress: false,
    isAppInitialized: false,
    authError: null
};

export default (state = defaultState, action: IAuthAction): IAuthState => {
	switch (action.type) {
        case AUTH.SIGN_IN_REQUEST:
            switch(action.payload?.status) {
                case ActionStatus.STARTED:
                    return {...state, isSignInInProgress: true, isLogged: false};
                case ActionStatus.SUCCESS:
                    return {...state, ...defaultState, isSignInInProgress: false, isLogged: true, isAppInitialized: true};
                case ActionStatus.FAILED:
                    return {...state, isSignInInProgress: false, isLogged: false, isAppInitialized: true, authError: action.payload.response}
                default:
                    return {...state, isSignInInProgress: false, isLogged: false, isAppInitialized: true};
            }

        case AUTH.REQUEST_USER_AUTH: 
            switch(action.payload?.status) {
                // case ActionStatus.STARTED:
                //     return {...state, isSignInInProgress: true, isLogged: false};
                case ActionStatus.SUCCESS:
                    return {...state, isLogged: true, isAppInitialized: true};
                case ActionStatus.FAILED:
                    return {...state, isLogged: false, isAppInitialized: true};
                default: 
                    return state;
            }
        case AUTH.SIGN_OUT: 
            return {...defaultState, isAppInitialized: true};
		default:
			return state;
	}
};