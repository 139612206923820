import React, { useState, useCallback, useEffect } from 'react';

import './index.css';
import AppIcon from '../app-icon';
import { BaseDropDown } from '../base-drop-down';
import { useDispatch, useSelector } from 'react-redux';
import { disableUser, deleteUser, updateUser, resendInvite } from 'actions/admin';
import { showUploadModal, showEditUserModal } from 'actions/modals';
import { IModel } from 'reducers/model';
import UserLogo from "components/user-logo";
import cx from 'classnames';
import { IUserInviteStatus } from 'services/firebase/interfaces';
import { DataStore } from 'services/dataStoreInterfaces';
import ConfirmationModal from 'components/confirmation-modal';
import { IAppState } from 'reducers';
import { getUserAdminRoleStatus } from 'utils/selectors';

interface Props {
    icon?: string;
    uid: string;
    name: string;
    email: string;
    disabled: boolean;
    status: string;
    companyName: string;
    companyId: string;
    userGroups: DataStore.UserGroupStoreData[];
    userModels: IModel[];
}

enum UserListActions {
    EDIT,
    ENABLE,
    DISABLE,
    DELETE,
    RESEND_INVITE,
}

interface IUserAction {
    label: string;
    id: UserListActions;
}

export default function UsersListItem({uid, name, email, status, companyName, companyId, disabled, userGroups, userModels}:Props) {
    const loggedUserName = useSelector((state: IAppState) => state.user.name)
    const [actionLabels, setActionLabels] = useState<IUserAction[]>([]);
    const dispatch = useDispatch();
    const [deleteLicenseVisible, setDeleteLicenseVisible] = useState(false);
    const hideDeleteModal = () => setDeleteLicenseVisible(false);
    const confirmUserDelete = () => {
        dispatch(deleteUser({ uid, companyId }))
        hideDeleteModal();
    };
    const isUserSuperAdmin = useSelector(getUserAdminRoleStatus);

    const onMoreMenuItemClicked = useCallback(
        id => {
            console.log(id);
            switch (id) {
                case UserListActions.EDIT:
                    dispatch(showEditUserModal({ uid }));
                    break;
                case UserListActions.ENABLE:
                case UserListActions.DISABLE:
                    dispatch(
                        disableUser({
                            uid,
                            companyId,
                            disabled: !disabled,
                        }),
                    );
                    break;
                case UserListActions.DELETE:
                    setDeleteLicenseVisible(true);
                    break;
                case UserListActions.RESEND_INVITE:
                    dispatch(
                        resendInvite({
                            name,
                            email,
                            callbackUrl: window.location.origin,
                            company: {
                                id: companyId,
                                name: companyName,
                            },
                        }),
                    );
                    break;
                default:
            }
        },
        [companyId, disabled, dispatch, uid, companyName, email, name],
    );
    useEffect(() => {
        const edit = {
            id: UserListActions.EDIT,
            label: 'Edit',
        };
        const resend = {
            id: UserListActions.RESEND_INVITE,
            label: 'Resend Invite',
        };
        const enable = {
            id: UserListActions.ENABLE,
            label: 'Enable',
        };
        const disable = {
            id: UserListActions.DISABLE,
            label: 'Disable',
        };
        const deleteAction = {
            id: UserListActions.DELETE,
            label: 'Delete',
        };

        let labels: IUserAction[] = [];
        if (loggedUserName !== name) {
            const disableLabel = disabled ? enable : disable;
            labels = [edit, disableLabel, deleteAction];
        }
        if (status === IUserInviteStatus.PENDING_REQUEST) {
            labels.push(resend);
        }

        setActionLabels(labels);
    }, [disabled, loggedUserName, name, status]);
    
    const onUploadModelClick = useCallback(
        () => dispatch(showUploadModal({uid})),
        [dispatch, uid],
    )

    const onDeleteModelClick = useCallback(
        () => dispatch(updateUser({uid, companyId, models:[]})),
        [companyId, dispatch, uid],
    )

    const renderStatus = () => {
        if(disabled) {
            return 'Inactive';
        }
        switch(status) {
            case IUserInviteStatus.PENDING_REQUEST: return 'Invited';
            case IUserInviteStatus.COMPLETED: return 'Active';
            default: return 'Inactive';
        }
    }
    
    return (
        <>
            <div className="user-list-item_icon">
                <UserLogo name={name} email={email} />
            </div>
            <div className="user-list-item_name-container">
                <div className="user-list-item_name">{name}</div>
                <div className="user-list-item_email">{email}</div>
            </div>
            <div className="user-list-item_status">
                <div
                    className={cx('user-list-item_status-badge', {
                        pending: !disabled && status === IUserInviteStatus.PENDING_REQUEST,
                        completed: !disabled && status === IUserInviteStatus.COMPLETED,
                        inactive: disabled || status === IUserInviteStatus.DISABLED,
                    })}
                >
                    {renderStatus()}
                </div>
            </div>
            <div className="user-list-item_company">{companyName}</div>
            <div className="user-list-item_group">{userGroups.map(_ => _.name)}</div>
            <div className="user-list-item_model-container">
                {userModels.length ? (
                    <div className="user-list-item_model-name-container">
                        <div className="user-list-item_model-name">
                            {userModels.map(_ => _.name)}
                        </div>
                        {isUserSuperAdmin && (
                            <AppIcon
                                onClick={onDeleteModelClick}
                                iconName="trash"
                                className="user-list-item_model-delete"
                            />
                        )}
                    </div>
                ) : (
                    <div onClick={onUploadModelClick} className="user-list-item_model-upload">
                        Upload model
                    </div>
                )}
            </div>
            <div className="user-list-item_action">
                <BaseDropDown
                    renderSelections={() =>
                        actionLabels.map((selection, key) => (
                            <div className="list-item" key={key} data-id={selection.id}>
                                {selection.label}
                            </div>
                        ))
                    }
                    onSelectionChanged={onMoreMenuItemClicked}
                >
                    <AppIcon iconName="more" />
                </BaseDropDown>
            </div>
            {deleteLicenseVisible && (
                <ConfirmationModal
                    title="Are you sure ?"
                    message="Once this action is confirmed, it cannot be undone."
                    cancelLabel="Cancel"
                    okLabel="Delete"
                    onCancel={hideDeleteModal}
                    onConfirm={confirmUserDelete}
                />
            )}
        </>
    );
};
