import React from 'react';
import { useDispatch } from 'react-redux';
import { filterSelectionChanged, calculateMetricsFilterSource } from '../../actions/filters'
import cx from 'classnames';

import './index.css';
import DropDown from '../drop-down';
import { IFilter } from '../../interfaces';
import { FilterLabels } from '../../utils/filterUtils';

interface Props {
    filter: IFilter
}

export default function Filter({ filter }: Props) {
    const { selections, label, selectionId } = filter;
    const selectedValue = selections[selectionId];
    const selectedValueClass = `selected-value${selectionId < 0 ? ' default' : ''}`;
    const dispatch = useDispatch();
    const onSelectionChanged = (selectionId: number) => {
        dispatch(filterSelectionChanged(label, selectionId));
        if (label === FilterLabels.VIEW) {
            dispatch(calculateMetricsFilterSource());
        }
    }

    return (
        <div className={cx('filter-container', filter.label.toLowerCase())}>
            {selectionId >= 0 && <div className={'highlighter'} />}
            <DropDown
                selectionId={selectionId}
                selections={selections}
                label={label}
                onSelectionChanged={onSelectionChanged}
            />
            <div className={selectedValueClass}>{selectedValue}</div>
        </div>
    )
}