import { createSelector } from 'reselect'
import { IAppState } from '../reducers'
import { getCurrentFilterValues } from './filterUtils';
import { IView, IColorItem, ViewTypeBaseStructure } from '../interfaces';
import { IFiltersState } from '../reducers/filters';
import memoize from 'lodash.memoize'
import { IUserGroups } from 'services/firebase/interfaces';
import { UserGroups } from 'constants/groups';

const getCapabilityById = ({ capabilities: { capabilities } }: IAppState, id: number) => capabilities.find(item => item.capabilityID === id);
const getSystemById = ({ capabilities: { system } }: IAppState, id: number) => system.find(item => item.systemID === id);
const getTeamById = ({ capabilities: { teams } }: IAppState, id: number) => teams.find(item => item.teamID === id);
const getLocationById = ({ filters: { locations } }: IAppState, id: number) => locations.find(item => item.locationID === id);
const getFilters = ({ filters }: IAppState) => filters;
export const isUserInAdminGroup = ({ user }: IAppState) => user.groups && user.groups.some(_ => _.name === IUserGroups.LPFM_ADMIN || _.name === IUserGroups.CLIENT_ADMIN);
export const hasUserSupportRole = ({ user }: IAppState) => user.groups && user.groups.some(_ => _.name === IUserGroups.EDITOR || _.name === IUserGroups.APPROVER);
export const getUserAdminRoleStatus = ({ user }: IAppState) => user.roles.superAdmin;
const getViews = ({ capabilities: { views } }: IAppState) => views;
export const getGroups = ({ groups }: IAppState) => groups;
export const getUser = ({ user }: IAppState) => user;
export const getModels = ({ models }: IAppState) => models;
const getGroupsList = ({ groups: {list} }: IAppState) => list;
const getModelsList = ({ models: {list} }: IAppState) => list;
export const getUsersList = ({ admin: {usersList} }: IAppState) => usersList;
export const getCompaniesListData = ({ admin: {companiesList} }: IAppState) => companiesList;
const getUserInvitations = ({ admin: { userInvitations } }: IAppState) => userInvitations;

export const getUserAdminStatus = createSelector(
    [isUserInAdminGroup, getUserAdminRoleStatus],
    (isUserInAdminsGroup, isUserSuperAdmin) => isUserInAdminsGroup || isUserSuperAdmin
)

export const getViewTypeDataById = (state:IAppState, viewTypeName:string, id:number):ViewTypeBaseStructure => 
    state.capabilities['capabilities'.toLowerCase()].find(_ => _.key === id)

export const makeGetCapabilityById = () => {
    return createSelector(
        [getCapabilityById],
        capability => capability
    )
}
export const makeGetSystemById = () => {
    return createSelector(
        [getSystemById],
        system => system
    )
}
export const makeGetTeamById = () => {
    return createSelector(
        [getTeamById],
        team => team
    )
}
export const makeGetLocationById = () => {
    return createSelector(
        [getLocationById],
        location => location
    )
}

export const makeGetAllFilteredViews = () => {
    return createSelector(
        [getFilters, getViews],
        (filters, views) => {
            return filterViews(filters, views, true);
        }
    )
}

export const makeGetFilteredViews = (views: IView[]) => {
    return createSelector(
        [getFilters],
        (filters) => {
            return filterViews(filters, views);
        }
    )
}


function filterViews(filters: IFiltersState, views: IView[], skipViewTypeCheck = false) {
    const filterValues = getCurrentFilterValues(filters.list);
    const viewFilter = filterValues.view;
    const metricsFilter = filterValues.metrics;
    const stateFilter = filters.states.find(item => item.name === filterValues.state)?.key;
    const locationFilter = filters.locations.find(item => item.name === filterValues.location)?.key;
    const businessFilter = filters.organizations.find(item => item.name === filterValues.business)?.key;
    const productFilter = filters.products.find(item => item.name === filterValues.product)?.key;
    const customerSegmentFilter = filters.customerSegments.find(item => item.name === filterValues.customerSegment)?.key;
    return views.filter(view => {
        const viewTypeMatches = skipViewTypeCheck || view.viewType === viewFilter;
        const metricMatches = view.metricType === metricsFilter;
        const stateMatches = !stateFilter || view.stateID === stateFilter;
        const locationMatches = !locationFilter || (view.locationID && view.locationID === locationFilter);
        const businessMatches = !businessFilter || (view.organisationID && view.organisationID === businessFilter);
        const productMatches = !productFilter || (view.productID && view.productID === productFilter);
        const customerSegmentMatches = !customerSegmentFilter || (view.customerSegmentID && view.customerSegmentID === customerSegmentFilter);

        return viewTypeMatches && metricMatches && stateMatches && locationMatches && businessMatches && productMatches && customerSegmentMatches;
    });
}

export const makeGetMetricConfigByView = (view: IView) => {
    return createSelector(
        [getFilters],
        (filters) => {
            return filters.viewMetricsMapping[view.viewType]
                .find(_ => 
                    _.metricType === view.metricType &&
                    _.metricGroup === view.viewMetric && 
                    _.metricSubType === view.metricSubType)   
        }
    )
}

export const getAppColors = createSelector(
    [getFilters],
    (filters) => memoize((name:string):IColorItem => filters.colors[name])
)

const groupSortValues = {
    // [UserGroups.LPFM_ADMIN]: 5,
    [UserGroups.LPFM_SUPPORT]: 4,
    [UserGroups.CLIENT_ADMIN]: 3,
    [UserGroups.APPROVER]: 2,
    [UserGroups.EDITOR]: 1,
    [UserGroups.READ_ONLY]: 0,
}

export const getGroupsNames = createSelector(
    [getGroupsList],
    (groups) => groups.map(_ => _.name).filter(_ => groupSortValues[_]).sort((groupA, groupB) => groupSortValues[groupB] - groupSortValues[groupA])
)

export const getModelsNames = createSelector(
    [getModelsList],
    (models) => models.map(_ => _.name)
)

export const getCompaniesNames = createSelector(
    [getCompaniesListData],
    (company) => company.map(_ => _.name)
)

export const makeGetUserInvitationForEmail = createSelector(
    [getUserInvitations],
    (userInvitations) => (email: string) => userInvitations.find(invitation => invitation.email === email)
)
