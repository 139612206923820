import firebase from 'firebase/app';
import 'firebase/auth';
import { getUserRef, getUserDataByUID, getUserGroups, updateUserByUID, getUserModels, getMetricsData } from './firestore';
import { IUserInviteStatus } from './interfaces';
import { DataStore } from 'services/dataStoreInterfaces';

const getUserData = async (user: firebase.User): Promise<DataStore.UserStoreData | {}> => {
    const { claims } = await getUserToken(user);
    return await isSuperAdmin(user)
        ? {}
        : await getUserDataByUID(claims.companyId, user.uid);
}

export const getUserToken = (user: firebase.User) => user.getIdTokenResult();
export const getUserTokenId = (user: firebase.User) => user.getIdToken();
export const getCurrentUser = () => firebase.auth().currentUser;
export const getCurrentUserToken = () => {
    const user = getCurrentUser();
    return user && getUserToken(user);
}
export const getCurrentUserTokenId = () => {
    const user = getCurrentUser();
    return user && getUserTokenId(user);
}

let unsubscribeUserOnSnaphot = () => { };
const SUPER_ADMIN_LEVEL = 80;
export const isSuperAdmin = async (user: firebase.User) => {
    const roles = await getUserRoles(user);
    return roles.admin && roles.admin === SUPER_ADMIN_LEVEL;
}


export const isAdmin = async (user: firebase.User) => {
    const roles = await getUserRoles(user);
    return Boolean(roles.admin);
}


export const getUserRoles = async (user: firebase.User) => {
    const { claims } = await getUserToken(user);
    // there are no roles for new users
    return claims.roles || {};
}

export const getUserRolesGroupsAndData = async (user: firebase.User) => {
    const [roles, data] = await Promise.all([
        getUserRoles(user),
        getUserData(user)
    ])
    const { claims } = await getUserToken(user);
    const groups = await getUserGroups(claims.companyId, user.uid);
    const models = await getUserModels(claims.companyId, user.uid);
    const promisesToResolve = models.map(async (model) => {
        const metrics = await getMetricsData(claims.companyId, model.id);
        return {
            ...model,
            metrics
        }
    })
    return await Promise.all(promisesToResolve)
        .then(models => ({ ...data, roles, groups, models, uid: user.uid }));
}
export const signIn = async (email: string, password: string) => {
    try {
        const { user } = await firebase
            .auth()
            .signInWithEmailAndPassword(email, password)

        if (user) {
            const userRolesGroupsAndData = await getUserRolesGroupsAndData(user);
            if (userRolesGroupsAndData.status === IUserInviteStatus.PENDING_REQUEST) {
                const { claims } = await getUserToken(user);
                await updateUserByUID(claims.companyId, user.uid, { status: IUserInviteStatus.COMPLETED });
            }
            return userRolesGroupsAndData;
        }
    }
    catch (err) {
        switch (err.code) {
            case 'permission-denied':
                throw new Error(`Licence not assigned. Please contact Support.`);

            case 'auth/user-disabled':
                throw new Error('User was disabled. Please contact support');
            case 'auth/user-not-found':
            case 'auth/invalid-email':
            case 'auth/wrong-password':
                throw new Error('Incorrect username or password.');
        }
        throw new Error(`Firebase Auth: can not signIn user with ${email}. ${err}`);
    }
}

export const signOut = () => {
    unsubscribeUserOnSnaphot();
    return firebase
        .auth()
        .signOut();
}


export const checkAuth = () =>
    new Promise((resolve, reject) => firebase
        .auth()
        .onAuthStateChanged(async user => {
            if (user) {
                const { claims } = await getUserToken(user);
                const userRef = getUserRef(claims.companyId, user.uid);
                unsubscribeUserOnSnaphot = userRef.onSnapshot(
                    () => user.getIdToken(true),
                    (error) => console.log(error)
                );
                getUserRolesGroupsAndData(user)
                    .then(resolve)
                    .catch(reject);
            } else {
                reject('user is not auth');
            }
        }))

export const onAuthStateChanged = (triggerFunc) => firebase.auth().onAuthStateChanged(triggerFunc);
export const onIdTokenChanged = (triggerFunc) => firebase.auth().onIdTokenChanged(triggerFunc);

export const resetPassword = (email:string) => {
    var actionCodeSettings = {
        url: window.location.origin,
        handleCodeInApp: true
    };
    return firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
}
