import { MODEL, APP } from '../constants/action-types'
import { IModelActions } from '../actions/model';
import { IActionAppReset, IActionAppResetFile } from '../actions/auth';

export interface IModel {
	id: string;
	name: string;
	supportEmail?: string;
	reportDate?: Date;
	group1Name?: string;
	group2Name?: string;
	group3Name?: string;
}

export interface IModelState extends IModel {
	
}

export const defaultState: IModelState = {
	id: '',
	name: '',
	supportEmail: ''
};

export default (state: IModelState = defaultState, action: IModelActions | IActionAppReset | IActionAppResetFile): IModelState => {
	switch (action.type) {
        case MODEL.UPDATE_MODEL_INFO:
			return {...state, ...action.payload};
		case APP.RESET_EVERYTHING:
		case APP.RESET_EVERYTHING_FILE:
			return {...state, ...defaultState};
		default:
			return state;
	}
};