import React, { Component } from 'react';
import classnames from 'classnames';

import './index.css';

export class BaseDropDown extends Component {
    state = {
        expanded: false,
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.hideSelections);
    }

    render() {
        const expanded = this.state.expanded;
        const dropdownClassNames = classnames('base-drop-down-container', { expanded })
        return (
            <div onClick={this.onToggle} className={dropdownClassNames}>
                {this.props.children}
                {expanded && 
                    <div onClick={this.onSelectionClick} className='base-drop-down-selections-container'>
                        {this.props.renderSelections()}
                    </div>}
            </div>
        )
    }

    hideSelections = () => {
        this.setState({expanded: false});
        this.props.onStateChanged(false);
        document.removeEventListener('click', this.hideSelections);
    }

    onToggle = (event) => {
        event && event.stopPropagation();
        if(!this.state.expanded) {
            document.addEventListener('click', this.hideSelections);
        }
        this.setState({expanded: !this.state.expanded});
        this.props.onStateChanged(!this.state.expanded);
    }

    onSelectionClick = (event) => {
        event.stopPropagation();
        this.onToggle();
        const nE = event.nativeEvent;
        const path = nE.path || (nE.composedPath && nE.composedPath())
        const selectionEl = path.find(_ => _.dataset && _.dataset.id !== undefined);
        const selectionId = selectionEl && parseInt(selectionEl.dataset.id);
        
        if(!isNaN(selectionId) && this.props.selectionId !== selectionId) {
            this.props.onSelectionChanged(selectionId);
        }
    }
}

BaseDropDown.defaultProps = {
    onStateChanged: () => {}
}
