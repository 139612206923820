import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showSystemModal, showCapabilityModal, showGeneralModal } from '../../actions/modals';
import './index.css';
import {
    ViewType,
    IView,
    ISystem,
    ICapability,
    ITeam,
    ViewTypeBaseStructure,
} from '../../interfaces';
import { IGenericModalItem } from '../generic-modal';
import { IAppState } from '../../reducers';
import { getViewTypeDataById } from '../../utils/selectors';
import LevelThreeLabel from './level-three-label';

interface Props {
    view: IView;
}

export default function LevelThreeSubject({ view }: Props) {
    const dispatch = useDispatch();
    const state = useSelector((state: IAppState) => state);

    let subject: ISystem | ICapability | ITeam | ViewTypeBaseStructure | undefined;
    subject = getViewTypeDataById(state, view.viewType, view.viewSubjectID);

    const onLabelClick = useCallback(
        event => {
            event.stopPropagation();
            switch (view.viewType) {
                case ViewType.SYSTEM:
                    return dispatch(showSystemModal(subject as ISystem, view));
                case ViewType.CAPABILITY:
                    return dispatch(showCapabilityModal(subject as ICapability));
                default:
                    return dispatch(showGeneralModal(subject as IGenericModalItem, view));
            }
        },
        [dispatch, subject, view],
    );

    if (!subject) {
        return <div>{view.viewType} not found</div>;
    }

    return (
        <LevelThreeLabel
            view={view}
            subject={{
                name: subject.name,
                fullName: subject.fullName || subject.name,
                description: subject.description,
                value: view.metricValue,
            }}
            visualType={view.visualType}
            onClick={onLabelClick}
        />
    );
}
