import { IFilter } from "../interfaces";

export const findFilter = (label: string, filters: IFilter[]) => filters.find(filter => filter.label === label);
export const getFilterValue = (label: string, filters: IFilter[]) => {
    const filter = findFilter(label, filters)!;
    const selection = filter.selections[filter.selectionId];
    return selection;
};

export enum FilterLabels {
    VIEW = 'VIEW',
    METRIC = 'METRIC',
    STATE = 'STATE',
    LOCATION = 'LOCATION',
    BUSINESS = 'BUSINESS',
    PRODUCT = 'PRODUCT',
    CLIENT = 'CLIENT'
};

/**
 * Transforms Filters List to Filters Map (filter:selectedValue)
 * @param  {Filters} filters Filters list
 */
export const getCurrentFilterValues = (filters: IFilter[]) => {
    return {
        view: getFilterValue(FilterLabels.VIEW, filters),
        metrics: getFilterValue(FilterLabels.METRIC, filters),
        state: getFilterValue(FilterLabels.STATE, filters),
        location: getFilterValue(FilterLabels.LOCATION, filters),
        business: getFilterValue(FilterLabels.BUSINESS, filters),
        product: getFilterValue(FilterLabels.PRODUCT, filters),
        customerSegment: getFilterValue(FilterLabels.CLIENT, filters)
    }
};