import './firebase';
import * as firebaseAuth from './firebase/auth';
import * as firebaseStore from './firebase/firestore';
import * as firebaseFunc from './firebase/func';

export const authService = firebaseAuth;
export const dataService = firebaseStore;
export const adminService = firebaseFunc;

export default {
    authService,
    dataService,
    adminService
}