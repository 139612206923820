import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import LabeledInput from '../../components/labeled-input'
import Button from '../../components/button'

import './index.css';
import { Link, useHistory } from 'react-router-dom';
import AppIcon from 'components/app-icon';
import { resetPassword } from 'services/firebase/auth';
import { showInfoToast, showErrorToast } from 'components/toast-with-icon';

const errorMessages = {
    general: 'something wrong...',
    default: {
        required: 'field is required',
    },
    email: {
        pattern: 'incorrect email format'
    },
    password: {
        minLength: 'password\'s length should be in range 4-20',
        maxLength: 'password\'s length should be in range 4-20'
    }
}

type EmailInputType = 'email';
type PasswordInputType = 'password';

interface ISignForm {
    email: EmailInputType;
    password: PasswordInputType;
}

const EMAIL:EmailInputType = 'email';
const PASSWORD:PasswordInputType = 'password';

export default function PasswordResetPage() {
    const history = useHistory();
    const [isResetInProgress, setResetProgress] = useState(false)
    const [email, changeEmail] = useState('');
    const { register, unregister, setValue, handleSubmit, errors, clearErrors} = useForm<ISignForm>();

    const onResetSubmit = () => {
        setResetProgress(true);
        resetPassword(email)
            .then(_ => {
                showInfoToast('Please check your inbox to reset your password.');
                history.replace('/signin');
        })
            .catch(_ => showErrorToast('Email address not found. Please check it and try again.'))
            .finally(() => setResetProgress(false));
    }

    const onEmailChangeHandler = useCallback(
        (event) => {
            clearErrors(EMAIL)
            setValue(EMAIL, event.target.value, { shouldValidate: true, shouldDirty: true});
            changeEmail(event.target.value)
        }, [clearErrors, setValue])

    useEffect(() => {
        register({ name: EMAIL, type: 'custom' }, {required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ });
        return () => {
            unregister([EMAIL, PASSWORD])
        }
    }, [register, unregister]);

    const getError = (field) => {
        if(!errors[field]) {
            return null;
        }
        if(errorMessages[field]) {
            return errorMessages[field][errors[field].type] || errorMessages.default[errors[field].type] || null;
        }
        return '';
    }

    return (
        <div id='sign-in-form-container'>
            <Link className='back-button' to='/signin' style={{'position':'absolute', 'left': '5rem'}}>
                <AppIcon iconName='back' />
            </Link>
            <form onSubmit={handleSubmit(onResetSubmit)}>
                <div className='title'>Reset password</div>
                <LabeledInput 
                    iconName='mail'
                    label={EMAIL}
                    placeholder='Your email'
                    onChange={onEmailChangeHandler}
                    autoComplete={EMAIL}
                    value={email}
                    type={EMAIL}
                    name={EMAIL}
                    error={getError(EMAIL)}
                    rounded
                />
                <div className='bot-container'>
                    <Button primary disabled={isResetInProgress} type="submit">Reset</Button>
                </div>
            </form>
        </div>
    );
}
