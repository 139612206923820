import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetSelection } from '../../actions/scenarios';

import './index.css';
import ScenarioItem from '../scenario-item';
import { IAppState } from '../../reducers';
import { showMetricBar } from '../../actions/layout';
import MetricBarsPane from '../metric-bars-pane';
import AppIcon from '../app-icon';
import classnames from 'classnames';

export default function ScenariosPanel () {
    const scenarios = useSelector(({ scenarios }:IAppState) => scenarios.list);
    const {appHeader} = useSelector(({ layout }: IAppState) => layout);
    const dispatch = useDispatch();
    const resetSelectionDispatch = useCallback(
        () => dispatch(resetSelection()),
        [dispatch]
    );
    const onClickHandler = useCallback(
        () => dispatch(showMetricBar({isVisible: !appHeader.isMetricBarVisible})),
        [dispatch, appHeader.isMetricBarVisible],
    )

    const routes = scenarios.map(scenario => <ScenarioItem key={scenario.scenarioID} scenario={scenario}/>)

    return (
        <div className='scenarios-panel'>
            <div className='label'>JOURNEYS</div>
            <div className='routes'>
                <span className='link' onClick={resetSelectionDispatch}>All</span>
                {routes}
            </div>
            <div className='show-metric-bar-button' onClick={onClickHandler}>KEY
                <AppIcon 
                    iconName='arrow_down'
                    className={classnames('show-metric-bar-button_icon', {expanded: appHeader.isMetricBarVisible})}
                />
            </div>
            
            {appHeader.isMetricBarVisible && <MetricBarsPane/>}
        </div>
    )
}
