import React, { useCallback, useState, useEffect } from 'react';
import './index.css';
import Button from '../button';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../drop-down';
import { getAllGroups, getAllModels } from '../../actions/groups';
import { ActionStatus } from '../../constants/action-statuses';
import { getUserGroups, getUserModels } from '../../services/firebase/firestore';
import { getGroupsNames, getGroups, getModels, getModelsNames, getUsersList, getUserAdminRoleStatus } from '../../utils/selectors';
import { IAppState } from '../../reducers';
import { updateUser } from '../../actions/admin';
import { toast } from 'react-toastify';
import { User } from 'services/dataStoreInterfaces';
import { hide } from 'actions/modals';

interface Props {
    uid: User.id
}

export default function EditUserModal({ uid }:Props) {
    const dispatch = useDispatch();
    const [groupId, setGroupId] = useState(2);
    const [modelId, setModelId] = useState(0);

    const state = useSelector((state: IAppState) => state);
    const groups = getGroups(state);
    const groupsNames = getGroupsNames(state);
    const models = getModels(state);
    const modelsNames = getModelsNames(state);
    const user = getUsersList(state).find(user => user.uid === uid)!;
    const isUserSuperAdmin = useSelector(getUserAdminRoleStatus);

    const onUserGroupUpdated = useCallback((id) => {
        setGroupId(id);
    }, [])
    const onUserModelUpdated = useCallback((id) => setModelId(id), [])
    const onUpdateButtonClicked = useCallback(
        () => {
            const newGroup = groups.list.find(({name}) => name === groupsNames[groupId]);
            const newModel = models.list.find(({name}) => name === modelsNames[modelId]);
            if(!user){
                return toast.error('There is no user for update');
            }
            dispatch(updateUser({
                uid,
                companyId: user.companyId,
                groups: newGroup ? [newGroup] : [],
                models: newModel ? [newModel] : []
            }));
            dispatch(hide());
        },
        [dispatch, groupId, groups.list, groupsNames, modelId, models.list, modelsNames, uid, user],
    )

    useEffect(() => {
        dispatch(getAllGroups(ActionStatus.STARTED, {companyId: user.companyId}));
        dispatch(getAllModels(ActionStatus.STARTED, {companyId: user.companyId}));
    }, [dispatch, user.companyId]);

    useEffect(() => {
        getUserGroups(user.companyId, uid).then(_ => {
            _.length && setGroupId(groupsNames.indexOf(_[0].name))
        });
        getUserModels(user.companyId, uid).then(_ => {
            _.length && setModelId(modelsNames.indexOf(_[0].name))
        });
    }, [groupsNames, modelsNames, user.companyId, uid]);
    
    return (
        <div id='edit-user-modal'>
            <div className='edit-user-modal_title'>Edit users</div>
            <div className='edit-user-modal_selectors-section'>
                <div className='edit-user-modal_selector-title'>USER GROUP</div>
                <DropDown
                    selectionId={groupId}
                    selections={groupsNames}
                    label={groupsNames[groupId]}
                    onSelectionChanged={onUserGroupUpdated}
                />
                { isUserSuperAdmin &&
                    <div className='edit-user-modal_selector-title'>MODEL</div> }
                { isUserSuperAdmin && <DropDown
                    selectionId={modelId}
                    selections={modelsNames}
                    label={modelsNames[modelId]}
                    onSelectionChanged={onUserModelUpdated}
                />}
            </div>
            <Button onClick={onUpdateButtonClicked} primary>Update</Button>
        </div>
    );
}

