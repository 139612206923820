import React from 'react';

import './index.css';
import { buildColorStyles } from '../../utils';
import { makeGetMetricConfigByView, getAppColors } from '../../utils/selectors';
import { IView } from '../../interfaces';
import { useSelector } from 'react-redux';
import { IAppState } from '../../reducers';
import MetricBarItem from '../metric-bar-item';

interface IMetricStateLabelProps {
    view: IView;
    showActualValue?: boolean;
}

export default function MetricStateLabel({
    view,
    showActualValue = false,
}: IMetricStateLabelProps) {
    const state = useSelector((state: IAppState) => state);
    const getMetricConfigByView = makeGetMetricConfigByView(view);
    const metric = getMetricConfigByView(state);

    if (!metric) {
        return null;
    }
    const getColors = getAppColors(state);
    const { backgroundColor, backgroundColorAlpha } = buildColorStyles(getColors(view.colour));

    const name = showActualValue ? `${metric.metricType}: ${view.metricValue}` : metric.metricType;

    return (
        <MetricBarItem name={name} innerColor={backgroundColor} outerColor={backgroundColorAlpha} />
    );
}
