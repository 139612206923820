import React, { Component } from 'react';

import './auth.css';

export default function auth(OriginalComponent: any) {
    return class extends Component {
       // make some enhancements
       // ...
        render() {
          // return original component with additional props
            return (
                <div id='auth-page-container'>
                    <div className='welcome-container'>
                        <div className='welcome'>
                            <div className='logoImage'>
                                <img src={'/modellr-logo.png'} alt="modellr logo"/>
                            </div>
                            <div className='title'>modellr</div>
                            <div className='subtitle'>Intelligent decision automation for change leaders</div>
                            <div className='featuresList'>
                                <ul>
                                    <li>See your consolidated business metrics</li>
                                    <li>View the impact of change on your organisation</li>
                                    <li>Identify change options to meet your targets</li>
                                    <li>Track project benefits</li>
                                </ul>
                            </div>
                            <div className='LPLogo'>
                                <img src={'/LP-logo.png'} alt="Leading Point logo"/>
                            </div>
                        </div>
                    </div>
                    <div className='auth-form-container'>
                        <OriginalComponent {...this.props} />
                    </div>
                </div>   
            )
        }
    }
}
