export enum ADMIN {
    INVITE_USER = 'INVITE_USER',
    RESEND_INVITE = 'RESEND_INVITE',
    USER_INVITED = 'USER_INVITED',
    DISABLE_USER = 'DISABLE_USER',
    DELETE_USER = 'DELETE_USER',
    UPDATE_USER = 'UPDATE_USER',
    GET_USERS_LIST = 'GET_USERS_LIST',
    UPDATE_USERS_LIST = 'UPDATE_USERS_LIST',

    ADD_LICENCE = 'ADD_LICENCE',
    UPDATE_LICENCE = 'UPDATE_LICENCE',
    DELETE_LICENCE = 'DELETE_LICENCE',

    ADD_COMPANY = 'ADD_COMPANY',
    UPDATE_COMPANY = 'UPDATE_COMPANY',
    DELETE_COMPANY = 'DELETE_COMPANY',
    GET_COMPANIES_LIST = 'GET_COMPANIES_LIST',
    UPDATE_COMPANIES_LIST = 'UPDATE_COMPANIES_LIST',
}
