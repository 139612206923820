import { IAdminAction } from '../actions/admin';
import { ADMIN, APP } from '../constants/action-types';
import { ActionStatus } from '../constants/action-statuses';
import { IModel } from './model';
import { DataStore } from 'services/dataStoreInterfaces';
import { toast } from 'react-toastify';

export interface ICompany {
    id: string;
    name: string;
}

interface IuserInvitation {
    email: string;
    isInProgress: boolean;
}

export interface IUserData extends DataStore.UserStoreDataWithId {
    models: IModel[];
    groups: DataStore.UserGroupStoreData[];
}

export interface IAdminState {
    usersList: IUserData[],
    userInvitations: IuserInvitation[],
    companiesList: ICompany[];
    usersByCompanies: Record<string, IUserData[]>;
}

const defaultState = {
    usersList: [],
    userInvitations: [],
    companiesList: [],
    usersByCompanies: {}
}

export default (state: IAdminState = defaultState, action: IAdminAction): IAdminState => {
	switch (action.type) {
        case ADMIN.INVITE_USER:
            let invitations:IuserInvitation[];
            switch(action.status.original) {
                case ActionStatus.STARTED:
                    invitations = state.userInvitations.reduce((acc, invitation) => {
                        if(invitation.email === action.payload.email){
                            acc.push({...invitation, isInProgress: true})
                        }else {
                            acc.push(invitation);
                        }
                        return acc;
                    }, [] as IuserInvitation[]);

                    const invitation = state.userInvitations.find(invite => invite.email === action.payload.email);
                    return {
                        ...state,
                        userInvitations: invitation 
                            ? [...invitations] 
                            : [...invitations, {email:action.payload.email, isInProgress: true}]
                    };
                case ActionStatus.SUCCESS:
                case ActionStatus.FAILED:
                    invitations = state.userInvitations.reduce((acc, invitation) => {
                        if(invitation.email === action.payload.email){
                            acc.push({...invitation, isInProgress: false})
                        }else {
                            acc.push(invitation)
                        }
                        return acc;
                    }, [] as IuserInvitation[]);
                    return {
                        ...state,
                        userInvitations: [...invitations]
                    };
                default:
                    return state;
            }
        case ADMIN.UPDATE_USERS_LIST:
            switch (action.status.original) {
                case ActionStatus.SUCCESS:
                    const usersInCompany = action.payload.length
                        ? {[action.payload[0].companyId]: action.payload}
                        : {};
                    const usersByCompanies = {
                        ...state.usersByCompanies,
                        ...usersInCompany
                    };
                    return {
                        ...state, 
                        usersByCompanies,
                        usersList: Object.values(usersByCompanies).reduce((acc, curr) => [...acc, ...curr])
                    };
                case ActionStatus.FAILED:
                    toast.error(action.payload);
                    return state;
                default:
                    return state;
            }
        case ADMIN.UPDATE_COMPANIES_LIST:
            switch (action.status.original) {
                case ActionStatus.SUCCESS:
                    return {...state, companiesList: action.payload};
                default:
                    return state;
            }
        case APP.RESET_EVERYTHING: {
            return defaultState;
        }
		default:
			return state;
	}
};