import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { resetAllFilters } from '../../actions/filters';
import AppIcon from '../app-icon/index';

import './index.css';
import Filter from '../filter';
import { BaseDropDown } from '../base-drop-down';
import { CURRENT_FILE_STORAGE_KEY } from '../../constants/file-content';
import { IAppState } from 'reducers';
import HTMLToPDF from 'pdf-service/htmlToPdf';
import { showMetricsUploadModal } from 'actions/modals';
import { getUserAdminStatus, hasUserSupportRole } from 'utils/selectors';

interface IMenuItem {
    id: MenuItemKey;
    label: string;
}

enum MenuItemKey {
    REFRESH_MODEL,
    NEW_MODEL,
    PDF_EXPORT,
    UPLOAD_METRICS,
}

export default function TopFiltersPanel() {
    const [newUploadRequested, setNewUploadRequested] = useState(false);
    const dispatch = useDispatch();
    const state = useSelector((state: IAppState) => state);
    const filters = useSelector((state: IAppState) => state.filters.list);
    const user = useSelector((state: IAppState) => state.user);
    const hasUserAdminRole = getUserAdminStatus(state);
    const menuItems: IMenuItem[] = [
        {
            id: MenuItemKey.REFRESH_MODEL,
            label: 'Refresh model',
        },
        {
            id: MenuItemKey.PDF_EXPORT,
            label: 'Export to PDF',
        },
    ];
    if (hasUserAdminRole || hasUserSupportRole(state)) {
        menuItems.unshift({
            id: MenuItemKey.UPLOAD_METRICS,
            label: 'Upload metric data',
        });
    }
    const onMoreMenuItemClicked = (id: MenuItemKey) => {
        switch (id) {
            case MenuItemKey.UPLOAD_METRICS:
                dispatch(
                    showMetricsUploadModal({
                        uid: user.uid,
                        companyId: user.companyId,
                        modelId: user.models[0].id,
                    }),
                );
                break;
            case MenuItemKey.REFRESH_MODEL:
                setNewUploadRequested(true);
                break;            
            case MenuItemKey.NEW_MODEL:
                setNewUploadRequested(true);
                break;
            case MenuItemKey.PDF_EXPORT:
                HTMLToPDF.generate();
                break;
            default:
        }
    };

    if (newUploadRequested) {
        localStorage.removeItem(CURRENT_FILE_STORAGE_KEY);
        return <Redirect to="/upload" />;
    }

    return (
        <div id="top-filters-panel">
            <div className="filters-list">
                {filters.map(filter => (
                    <Filter filter={filter} key={filter.label} />
                ))}
            </div>
            <div className="manage-buttons-container">
                <div
                    className="manage-button-container"
                    onClick={() => dispatch(resetAllFilters())}
                >
                    <div>
                        <AppIcon iconName={'rotate'} />
                    </div>
                    <label className="label">Reset</label>
                </div>
                <BaseDropDown
                    renderSelections={() =>
                        menuItems.map(selection => (
                            <div className="list-item" key={selection.id} data-id={selection.id}>
                                {selection.label}
                            </div>
                        ))
                    }
                    onSelectionChanged={onMoreMenuItemClicked}
                >
                    <div className="manage-button-container">
                        <div>
                            <AppIcon iconName={'more'} />
                        </div>
                        <label className="label">More</label>
                    </div>
                </BaseDropDown>
            </div>
        </div>
    );
}
