import React, { SyntheticEvent } from 'react';
import { ReactComponent as UserSvg } from 'assets/user.svg';
import { ReactComponent as MailSvg } from 'assets/mail.svg';
import { ReactComponent as KeySvg } from 'assets/key.svg';
import { ReactComponent as CompanySvg } from 'assets/company.svg';
import { ReactComponent as EyeSvg } from 'assets/eye.svg';
import { ReactComponent as RotateSvg } from 'assets/rotate.svg';
import { ReactComponent as MoreSvg } from 'assets/more.svg';
import { ReactComponent as BackSvg } from 'assets/back.svg';
import { ReactComponent as ExcelSvg } from 'assets/Excel.svg';
import { ReactComponent as TrashCsv } from 'assets/trash.svg';
import { ReactComponent as ArrowLeftSvg } from 'assets/arrow-left.svg';
import { ReactComponent as ArrowRightSvg } from 'assets/arrow-right.svg';
import { ReactComponent as ArrowUpSvg } from 'assets/arrow-up.svg';
import { ReactComponent as ArrowDownSvg } from 'assets/arrow-down.svg';
import { ReactComponent as ExpandSvg } from 'assets/expand.svg';
import { ReactComponent as CollapseSvg } from 'assets/collapse.svg';
import { ReactComponent as CloseSvg } from 'assets/close.svg';
import { ReactComponent as SuccessSvg } from 'assets/success.svg';
import { ReactComponent as PlaySvg } from 'assets/play.svg';
import { ReactComponent as SettingsSvg } from 'assets/settings.svg';
import { ReactComponent as FaqSvg } from 'assets/faq.svg';
import { ReactComponent as SortSvg } from 'assets/sort.svg';

import './index.css';

const iconByName = {
    user: <UserSvg />,
    mail: <MailSvg />,
    company: <CompanySvg />,
    key: <KeySvg />,
    eye: <EyeSvg />,
    more: <MoreSvg />,
    back: <BackSvg />,
    Excel: <ExcelSvg />,
    close: <CloseSvg />,
    expand: <ExpandSvg />,
    collapse: <CollapseSvg />,
    arrow_left: <ArrowLeftSvg />,
    arrow_right: <ArrowRightSvg />,
    arrow_up: <ArrowUpSvg />,
    arrow_down: <ArrowDownSvg />,
    trash: <TrashCsv />,
    success: <SuccessSvg />,
    rotate: <RotateSvg />,
    play: <PlaySvg />,
    faq: <FaqSvg />,
    settings: <SettingsSvg />,
    sort: <SortSvg />,
};

interface Props {
    className?: string;
    iconName: string;
    onClick?: (event: SyntheticEvent) => void;
}

const AppIcon = ({ className, iconName, onClick }: Props) => 
    <span 
        className={`app-icon ${className || ''}`} 
        onClick={onClick}
    >
        {iconByName[iconName]}
    </span>;

export default AppIcon;