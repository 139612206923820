import React from 'react';
import './index.css';

interface Props {
    str: string;
}

export default React.memo(function FormattedText ({ str }: Props) {    
    if(!str) {
        return null;
    }
    const rows = str.toString().split('\n');
    let isListStarted = false;
    let list:any[];
    return (
        <div className='formatted-text-container'>
            {rows.reduce((acc:JSX.Element[], row:string, index)=>  {
                const regex = /\s*\*\s*(?<bullet>.*)\n?/gm;
                const match = regex.exec(row);
                if(match && match.groups && match.groups.bullet) {
                    if(!isListStarted){
                        list = [];
                        isListStarted = true;
                    }
                    list.push(<li key={match.groups.bullet+index}>{match.groups.bullet}</li>)
                    return acc;
                }
                if(isListStarted) {
                    acc.push(<ul key={index}>{list}</ul>);
                    isListStarted = false;
                }
                acc.push(<div key={row}>{row}</div>);
                return acc;
            }, [])}
        </div>
    )
})

