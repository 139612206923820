import React from 'react';
import './index.css';
import { useSelector } from 'react-redux';
import CapabilityTree, { ICapabilityTreeStructure } from '../capability-tree';
import groupBy from 'lodash/groupBy';
import { IAppState } from '../../reducers';
import { ICapability, ViewType } from '../../interfaces';
import { makeGetCapabilityById } from '../../utils/selectors';
import FormattedText from '../formatted-text';

interface Props {
    capability: ICapability;
}

export default function CapabilityModal({ capability }: Props) {
    const state = useSelector((state: IAppState) => state);

    const views = useSelector(({ capabilities: { views } }: IAppState) => views)
    const getCapabilityById = makeGetCapabilityById();

    if (!capability) {
        return <div>capability not found</div>;
    }

    const capabilityViews = views.filter(view => view.viewType === ViewType.CAPABILITY);
    const firstLevelGroup = groupBy(capabilityViews, 'L1ID');
    let capabilityTreeElements = [] as JSX.Element[];

    if (firstLevelGroup[capability.capabilityID]) { // current capability is top level, get all 2nd and 3rd levels tree
        const secondLevelGroup = groupBy(firstLevelGroup[capability.capabilityID], 'capID');
        capabilityTreeElements = Object.keys(secondLevelGroup).map(capID => {
            const capability = getCapabilityById(state, +capID)!
            const capabilityTree = {
                capability,
                childrenTree: Object.keys(groupBy(secondLevelGroup[capID], 'viewSubjectID')).map(viewSubjectID => ({
                    capability: getCapabilityById(state, +viewSubjectID)!
                } as ICapabilityTreeStructure))
            } as ICapabilityTreeStructure;

            return <CapabilityTree capabilityTree={capabilityTree} key={capID}/>
        });
    } else {
        const secondLevelGroup = groupBy(capabilityViews, 'capID');
        if (secondLevelGroup[capability.capabilityID]) {
            capabilityTreeElements = Object.keys(groupBy(secondLevelGroup[capability.capabilityID], 'viewSubjectID')).map(viewSubjectID => {
                const capabilityTree = {
                    capability: getCapabilityById(state, +viewSubjectID)!
                };
                return <CapabilityTree capabilityTree={capabilityTree} key={viewSubjectID}/>
            });
        }
    }

    return (
        <div id='capability-modal'>
            <div className='overview-container'>
                <h2 className='title'>{capability.name}</h2> 
                <div className='definition-container'>
                    <h3>Definition</h3>
                    <div className='content'>
                        <FormattedText str={capability.description}/>
                    </div>
                </div>
            </div>
            {!!capabilityTreeElements.length && <div className='modal-section-container'>
                <h2>Capabilities</h2>
                <div className='sub-title'>{capability.name} includes the following capabilities</div>
                { capabilityTreeElements }
            </div>}
        </div>
    );
}

