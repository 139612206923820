import React, {useCallback} from 'react';

import './index.css';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../../reducers';
import AppIcon from '../app-icon';
import { showMetricBar } from '../../actions/layout';
import MetricBarItem from '../metric-bar-item';
import { buildColorStyles } from '../../utils';

interface Props {
    title: string;
    subType: string;
}

export default function MetricBar ({title, subType} :Props) {
    const dispatch = useDispatch()
    const {colors, list, viewMetricsMapping} = useSelector(({ filters }: IAppState) => filters);
    const onClose = useCallback(() => dispatch(showMetricBar({isVisible: false})), [dispatch]);

    const viewFilter = list.find(filter => filter.label === 'VIEW');
    const metricFilter = list.find(filter => filter.label === 'METRIC');
    if(!viewFilter || !metricFilter) {
        return null;
    }
    const activeViewFilterSelection = viewFilter.selections[viewFilter.selectionId];
    const activeMetricFilterSelection = metricFilter.selections[metricFilter.selectionId];

    if(!activeMetricFilterSelection || !activeViewFilterSelection) {
        return null;
    }

    const viewMetricSubTypes = viewMetricsMapping[activeViewFilterSelection]
        .filter(view => view.metricType === activeMetricFilterSelection && view.metricSubType === subType)

        // .filter(view => view.metricType === activeMetricFilterSelection &&
        //         view.metricSubType === subType && 
        //         state.capabilities.views.find(_ => 
        //             _.viewType === activeViewFilterSelection &&
        //             _.metricType === activeMetricFilterSelection &&
        //             (
        //                 ((view.metricValueType === 'Numeric' ||
        //                 view.metricValueType === 'EUR/yr' || 
        //                 view.metricValueType === 'Percentage') && ((_.metricValue > view.metricValueFrom &&
        //                 _.metricValue <= view.metricValueTo) || _.metricValue > view.metricValueTo))
        //             || 
        //                 (view.metricValueType === 'Text' && _.metricValue === view.metricValueTo)))
        //             );

    return (
        <div className='metric-bar_container'>
            <div className='metric-bar_label'>{title}</div>
                {viewMetricSubTypes.map(item => {
                    const {backgroundColor} = buildColorStyles(colors[item.colour]);
                    return <MetricBarItem
                            key={JSON.stringify(item)}
                            name={item.metricGroup}
                            outerColor={backgroundColor}
                            innerColor={backgroundColor}
                        />
                })}
            <AppIcon className='metric-bar_close-button' iconName='close' onClick={onClose}/>
        </div>
    )
}
