import { CAPABILITIES } from "../constants/capabilities";
import { IAction } from "../reducers";
import { ICapability, IActor, IView, ISystem, ITeam, ViewTypeBaseStructure, ViewType, IViewMetric } from "../interfaces";

export interface IReceivedCapabilitiesPayload {
    capabilities: ICapability[]
}
export interface IReceivedSystemsPayload {
    systems: ISystem[]
}
export interface IReceivedTeamsPayload {
    teams: ITeam[]
}

export interface IReceivedActorsPayload {
    actors: IActor[]
}

export interface IReceivedViewsPayload {
    views: IView[]
}

export interface IUpdateViewMetricsPayload {
    metrics: IViewMetric[]
}

export type IReceivedViewTypeDataPayload = Record<ViewType, ViewTypeBaseStructure[]>;

export interface IActionReceivedCapabilities extends IAction<typeof CAPABILITIES.CAPABILITIES_RECEIVED, IReceivedCapabilitiesPayload> { }
export interface IActionReceivedSystems extends IAction<typeof CAPABILITIES.SYSTEMS_RECEIVED, IReceivedSystemsPayload> { }
export interface IActionReceivedTeams extends IAction<typeof CAPABILITIES.TEAMS_RECEIVED, IReceivedTeamsPayload> { }
export interface IActionReceivedActors extends IAction<typeof CAPABILITIES.ACTORS_RECEIVED, IReceivedActorsPayload> { }
export interface IActionReceivedViews extends IAction<typeof CAPABILITIES.RECEIVED_VIEWS_DATA, IReceivedViewsPayload> { }
export interface IActionUpdateViewMetrics extends IAction<typeof CAPABILITIES.UPDATE_METRICS_DATA, IUpdateViewMetricsPayload> { }
export interface IActionReceivedViewTypeData extends IAction<typeof CAPABILITIES.RECEIVED_VIEW_TYPE_DATA, IReceivedViewTypeDataPayload> { }

export const receivedCapabilities = (capabilities: ICapability[]): IActionReceivedCapabilities => ({
    type: CAPABILITIES.CAPABILITIES_RECEIVED,
    payload: { capabilities }
})
export const receivedSystem = (systems: ISystem[]): IActionReceivedSystems => ({
    type: CAPABILITIES.SYSTEMS_RECEIVED,
    payload: { systems }
})
export const receivedTeams = (teams: ITeam[]): IActionReceivedTeams => ({
    type: CAPABILITIES.TEAMS_RECEIVED,
    payload: { teams }
})

export const receivedActors = (actors: IActor[]): IActionReceivedActors => ({
    type: CAPABILITIES.ACTORS_RECEIVED,
    payload: { actors }
})

export const receivedViews = (views: IView[]): IActionReceivedViews => ({
    type: CAPABILITIES.RECEIVED_VIEWS_DATA,
    payload: { views }
})

export const updateViewMetrics = (metrics: IViewMetric[]): IActionUpdateViewMetrics => ({
    type: CAPABILITIES.UPDATE_METRICS_DATA,
    payload: { metrics },
});

export const receivedViewTypeData = (structures: Record<ViewType, ViewTypeBaseStructure[]>): IActionReceivedViewTypeData => ({
    type: CAPABILITIES.RECEIVED_VIEW_TYPE_DATA,
    payload: structures
})

export type ICapabilitiesActions =
  | IActionReceivedCapabilities
  | IActionReceivedActors
  | IActionReceivedViews
  | IActionReceivedSystems
  | IActionReceivedTeams
  | IActionUpdateViewMetrics
  | IActionReceivedViewTypeData;