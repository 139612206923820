import { ADMIN, USER} from '../constants/action-types'
import { IAction, IActionWithStatus } from '../reducers'
import { IModel } from '../reducers/model'
import { ActionStatus } from '../constants/action-statuses'
import { IUserData, ICompany } from '../reducers/admin'
import { DataStore, Company, Licence, Group } from 'services/dataStoreInterfaces'
import { IActionAppReset } from './auth'

export interface IResendInviteUserPayload {
    name: string;
    email: string;
    company: ICompany;
    callbackUrl: string;
}

export interface IInviteUserPayload extends IResendInviteUserPayload {
    groups: Group.name[];
}

export interface IAddLicencePayload {
    companyId: Company.Id;
    name: Licence.name;
    users: Licence.users;
    renewAt: Licence.renewAt;
}

export interface IUpdateLicencePayload extends IAddLicencePayload {
    licenseId: Licence.id;
}

export interface IDeleteLicencePayload {
    id: string;
    companyId: string;
}

export interface IDisableUserPayload {
    uid: string;
    companyId: string;
    disabled: boolean;
}

export interface IDeleteUserPayload {
    uid: string;
    companyId: string;
}

export interface IUpdateUserPayload {
    uid: string;
    companyId: string;
    groups?: DataStore.UserGroupStoreData[];
    models?: IModel[];
}
export interface IUserInvitedPayload {
    status: ActionStatus;
    response?: any;
}
export interface IGetUsersListPayload {
    companyId: string;
};
export type IUpdateUsersListPayload = IUserData[];
export type IUpdateCompaniesListPayload = ICompany[];
export type IModelsChangedPayload = IModel[];

export interface IAddCompanyPayload {
    name: string;
};
export interface IUpdateCompanyPayload {
    id: string;
    users?: string[];
    groups?: string[];
    licences?: string[];
    models?: string[];
};
export interface IDeleteCompanyPayload {
    id: string;
};
export interface ISubscribeModelUpdatesPayload {
    uid: string;
    companyId: string;
};

export interface IActionIAddLicence extends IAction<typeof ADMIN.ADD_LICENCE, IAddLicencePayload> { }
export interface IActionIUpdateLicence extends IAction<typeof ADMIN.UPDATE_LICENCE, IUpdateLicencePayload> { }
export interface IActionIDisableUser extends IAction<typeof ADMIN.DISABLE_USER, IDisableUserPayload> { }
export interface IActionIDeleteUser extends IAction<typeof ADMIN.DELETE_USER, IDeleteUserPayload> { }
export interface IActionIUpdateUser extends IAction<typeof ADMIN.UPDATE_USER, IUpdateUserPayload> { }
export interface IActionIUserInvited extends IAction<typeof ADMIN.USER_INVITED, IUserInvitedPayload> { }
export interface IActionIModelChanged extends IAction<typeof USER.MODELS_CHANGED, IModelsChangedPayload> { }
export interface IActionISubscribeModelUpdates extends IAction<typeof USER.SUBSCRIBE_MODEL_UPDATES, ISubscribeModelUpdatesPayload> { }

export interface ActionWithStatusGetUsersList extends IActionWithStatus<typeof ADMIN.GET_USERS_LIST, IGetUsersListPayload> { }
export interface ActionWithStatusGetCompaniesList extends IActionWithStatus<typeof ADMIN.GET_COMPANIES_LIST> { }
export interface ActionWithStatusUpdateCompaniesList extends IActionWithStatus<typeof ADMIN.UPDATE_COMPANIES_LIST, IUpdateCompaniesListPayload>{};
export interface ActionWithStatusInviteUser extends IActionWithStatus<typeof ADMIN.INVITE_USER, IInviteUserPayload>{};
export interface ActionWithStatusResendInviteUser extends IActionWithStatus<typeof ADMIN.RESEND_INVITE, IResendInviteUserPayload>{};
export interface ActionWithStatusUpdateUsersList extends IActionWithStatus<typeof ADMIN.UPDATE_USERS_LIST, IUpdateUsersListPayload>{};

export interface ActionWithStatusAddCompany extends IActionWithStatus<typeof ADMIN.ADD_COMPANY, IAddCompanyPayload>{};
export interface ActionWithStatusUpdateCompany extends IActionWithStatus<typeof ADMIN.UPDATE_COMPANY, IUpdateCompanyPayload>{};
export interface ActionWithStatusDeleteCompany extends IActionWithStatus<typeof ADMIN.DELETE_COMPANY, IDeleteCompanyPayload>{};

export interface ActionWithStatusDeleteLicence extends IActionWithStatus<typeof ADMIN.DELETE_LICENCE, IDeleteLicencePayload>{};

const createActionWithStatus = <T>(type:T): <P>(payload:P, status?:ActionStatus)=>IActionWithStatus<T, P> => 
    (payload, status=ActionStatus.STARTED) => ({
        type,
        status: {
            isStarted: status === ActionStatus.STARTED,
            isSuccess: status === ActionStatus.SUCCESS,
            isFailed: status === ActionStatus.FAILED,
            original: status
        },
        payload
    })

export const inviteUser:(payload:IInviteUserPayload, status?:ActionStatus)=>ActionWithStatusInviteUser = 
    createActionWithStatus(ADMIN.INVITE_USER);

export const resendInvite:(payload:IResendInviteUserPayload, status?:ActionStatus)=>ActionWithStatusResendInviteUser = 
    createActionWithStatus(ADMIN.RESEND_INVITE);

export const updateUsersList:(payload:IUpdateUsersListPayload, status?:ActionStatus)=>ActionWithStatusUpdateUsersList = 
    createActionWithStatus(ADMIN.UPDATE_USERS_LIST);

export const getUsersList:(payload:IGetUsersListPayload, status?:ActionStatus)=>ActionWithStatusGetUsersList = 
    createActionWithStatus(ADMIN.GET_USERS_LIST);
export const getCompaniesList:(payload:{}, status?:ActionStatus)=>ActionWithStatusGetCompaniesList = 
    createActionWithStatus(ADMIN.GET_COMPANIES_LIST);
export const updateCompaniesList:(payload:IUpdateCompaniesListPayload, status?:ActionStatus)=>ActionWithStatusUpdateCompaniesList = 
    createActionWithStatus(ADMIN.UPDATE_COMPANIES_LIST);

export const addCompany:(payload:IAddCompanyPayload, status?:ActionStatus)=>ActionWithStatusAddCompany = 
    createActionWithStatus(ADMIN.ADD_COMPANY);
export const updateCompany:(payload:IUpdateCompanyPayload, status?:ActionStatus)=>ActionWithStatusUpdateCompany = 
    createActionWithStatus(ADMIN.UPDATE_COMPANY);
export const deleteCompany:(payload:IDeleteCompanyPayload, status?:ActionStatus)=>ActionWithStatusDeleteCompany = 
    createActionWithStatus(ADMIN.DELETE_COMPANY);

export const modelsChanged = (payload: IModelsChangedPayload): IActionIModelChanged => ({
    type: USER.MODELS_CHANGED,
    payload
})
export const subscribeModelUpdates = (payload: ISubscribeModelUpdatesPayload): IActionISubscribeModelUpdates => ({
    type: USER.SUBSCRIBE_MODEL_UPDATES,
    payload
})

export const addLicence = (payload: IAddLicencePayload): IActionIAddLicence => ({
    type: ADMIN.ADD_LICENCE,
    payload
})
export const updateLicence = (payload: IUpdateLicencePayload): IActionIUpdateLicence => ({
    type: ADMIN.UPDATE_LICENCE,
    payload
})
export const deleteLicence:(payload:IDeleteLicencePayload, status?:ActionStatus)=>ActionWithStatusDeleteLicence = 
    createActionWithStatus(ADMIN.DELETE_LICENCE);

export const userInvited = (status:ActionStatus, response?:any): IActionIUserInvited => ({
    type: ADMIN.USER_INVITED,
    payload: {status, response}
})

export const disableUser = (payload: IDisableUserPayload): IActionIDisableUser => ({
    type: ADMIN.DISABLE_USER,
    payload
})

export const deleteUser = (payload: IDeleteUserPayload): IActionIDeleteUser => ({
    type: ADMIN.DELETE_USER,
    payload
})

export const updateUser = (payload: IUpdateUserPayload): IActionIUpdateUser => ({
    type: ADMIN.UPDATE_USER,
    payload
})


export type IAdminAction = 
      ActionWithStatusInviteUser
    | ActionWithStatusResendInviteUser
    | ActionWithStatusGetUsersList
    | ActionWithStatusUpdateUsersList

    | IActionIDisableUser
    | IActionIDeleteUser
    | IActionIUpdateUser
    | IActionIUserInvited

    | ActionWithStatusAddCompany
    | ActionWithStatusUpdateCompany
    | ActionWithStatusDeleteCompany
    | ActionWithStatusGetCompaniesList
    | ActionWithStatusUpdateCompaniesList

    | IActionIAddLicence
    | IActionIUpdateLicence
    | ActionWithStatusDeleteLicence
    
    | IActionAppReset
    | IActionIModelChanged
    | IActionISubscribeModelUpdates;