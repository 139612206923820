import React from 'react';
import classnames from 'classnames';

import './index.css';

export default function Toggler ({active=false, onChange=()=>{}}) {
    return (
        <div className={classnames('toggler-tub', {active})} onClick={() => onChange(!active)}>
            <div className='head'/>
        </div>
    )
}
