import React from 'react';

import './index.css';
import UserLogo from "components/user-logo";

interface Props {
    icon?: string;
    id: string;
    name: string;
}

export default function CompaniesListItem({id, name}:Props) {
    return (
        <>
            <div className="user-list-item_icon">
                <UserLogo name={name} email={name} />
            </div>
            <div className="user-list-item_company">{name}</div>
        </>
    );
}
