import XLSX from 'xlsx';
import groupBy from 'lodash/groupBy';
import mem from 'mem';
import {
    IView,
    ICapability,
    ILocation,
    IState,
    IProduct,
    IOrganization,
    ICustomerSegment,
    IModelData,
    IScenarioStep,
    IColorItem,
    ViewTypeBaseStructure,
    ViewType,
    IScenario
} from '../interfaces';
import { IViewMetricItem } from '../actions/filters';
import { toast } from 'react-toastify';

function safeRunAndLog<T>(cb: Function, error: string): T {
    try {
        return cb() as T;
    } catch(err) {
        toast.error(error);
        console.warn(error);
        console.warn(err);
        throw new Error("Something went wrong while file parsing.");
    }
}

export default (bstr: any) => {
    const getSheetByName = mem((name: string) => workbook.Sheets[name]);

    const workbook = XLSX.read(bstr, { type: 'binary' });

    const convertSheetToObjectsArray = <T>(worksheetName: string, opts?: XLSX.Sheet2JSONOpts | undefined) => {
        const worksheet = getSheetByName(worksheetName);
        const parsed = safeRunAndLog<T[]>(() => XLSX.utils.sheet_to_json(worksheet, opts), `Error while parsing: ${worksheetName}`);
        // console.log(`Parsed ${worksheetName}`);
        return parsed;
    };

    const modelData = convertSheetToObjectsArray<IModelData>('Model Data')[0];

    const views = convertSheetToObjectsArray<IView>('views');
    const viewMetricMapping = convertSheetToObjectsArray<IViewMetricItem>('thresholds');
    const allPossibleViewTypes = Array.from(new Set(views.map(_ => _.viewType)));
    // console.log(`allPossibleViewTypes: ${allPossibleViewTypes}`);

    // view data start
    const capabilities = convertSheetToObjectsArray<ICapability>('Capability');
    const actors = capabilities.filter(capability => capability.element === 'Actor');

    const groupBy1 = <T>(arr: T[], key: string): Record<string, T> => arr.reduce((acc, curr) => {
        acc[curr[key]] = curr;
        return acc;
    }, {});

    const colors = groupBy1(convertSheetToObjectsArray<IColorItem>('Colours'), 'colourName');

    // filters start
    const locations = convertSheetToObjectsArray<ILocation>('Location');
    const states = convertSheetToObjectsArray<IState>('State');
    const products = convertSheetToObjectsArray<IProduct>('Product');
    const organisations = convertSheetToObjectsArray<IOrganization>('Business');
    const customerSegments = convertSheetToObjectsArray<ICustomerSegment>('Client');
    // filters end

    // view items start
    const viewTypesStructures = allPossibleViewTypes.reduce((acc, possibleViewType) => {
        acc[possibleViewType.toLowerCase()] = convertSheetToObjectsArray<ViewTypeBaseStructure>(possibleViewType)
       return acc;
    }, {} as Record<ViewType, ViewTypeBaseStructure[]>);
    // view items end

    const viewMetricsMappingGrouped: Record<string, IViewMetricItem[]> = groupBy(viewMetricMapping, 'viewType');

    const scenariosData = convertSheetToObjectsArray<IScenarioStep>('Scenarios').filter(scenario => scenario.scenarioID);
    const scenariosGrouped: Record<string, IScenarioStep[]> = groupBy(scenariosData, 'scenarioID');
    const scenarios = safeRunAndLog<IScenario[]>(() =>
        Object.keys(scenariosGrouped).map(scenarioID => {
            const steps = scenariosGrouped[scenarioID].sort((a, b) => a.stepID.toString().localeCompare(b.stepID.toString(), undefined, { numeric: true }));
            const step = {
                scenarioID,
                name: steps[0].name,
                description: steps[0].description,
                steps
            } as IScenario;
            return step;
        })
        , 'There was an error while parsing scenarios.'
    );

    return {
        capabilities,
        actors,
        viewMetricMapping: viewMetricsMappingGrouped,
        locations,
        states,
        views,
        products,
        organisations,
        customerSegments,
        scenarios,
        modelData,
        colors,
        viewTypesStructures
    }
};
