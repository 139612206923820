import { LAYOUT } from "../constants/layout";
import { ILayoutActions } from "../actions/layout";

export interface IAppHeaderLayout {
    isMetricBarVisible: boolean;
}

export interface ILayoutState {
    appHeader: IAppHeaderLayout;
}

export const defaultState: ILayoutState = {
    appHeader: {
        isMetricBarVisible: true,
    },
};

export default (state: ILayoutState = defaultState, action: ILayoutActions): ILayoutState => {
    switch (action.type) {
        case LAYOUT.SHOW_METRIC_BAR:
            return { ...state, appHeader: {...state.appHeader, isMetricBarVisible: action.payload.isVisible} }
        default:
            return state;
    }
};
