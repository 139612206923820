import React from 'react';
import './index.css';

import AppIcon from '../app-icon/index';
import { BaseDropDown } from '../base-drop-down';

export default function CollapsedContainerHeader({label, onClick, isCollapseIconVisible, isCollapsed, renderMoreButtonSelections, onMoreButtonSelectionsChanged}) {
    return (
        <div className='collapsable-container-header' onClick={onClick} >
            {isCollapseIconVisible && 
                <AppIcon 
                    iconName={isCollapsed ? 'arrow_down' : 'arrow_up'} 
                    className='icon-collapsable'
                />
            }
            <div className='label'>{label}</div>
            <BaseDropDown 
                renderSelections={renderMoreButtonSelections}
                onSelectionChanged={onMoreButtonSelectionsChanged}
            >
                <AppIcon iconName='more' className='icon-more'/>
            </BaseDropDown>
        </div>
    )
}

