export enum ModalActions {
    SHOW_SYSTEM = 'SHOW_SYSTEM',
    SHOW_CAPABILITY = 'SHOW_CAPABILITY',
    SHOW_ACTOR = 'SHOW_ACTOR',
    SHOW_GENERAL = 'SHOW_GENERAL',
    SHOW_INVITE_USER = 'SHOW_INVITE_USER',
    SHOW_ADD_LICENCE = 'SHOW_ADD_LICENCE',
    SHOW_ADD_COMPANY = 'SHOW_ADD_COMPANY',
    SHOW_UPLOAD_MODAL = 'SHOW_UPLOAD_MODAL',
    SHOW_EDIT_USER_MODAL = 'SHOW_EDIT_USER_MODAL',
    SHOW_EDIT_LICENSE_MODAL = 'SHOW_EDIT_LICENSE_MODAL',
    SHOW_CONTACT_SUPPORT = 'SHOW_CONTACT_SUPPORT',
    SHOW_METRICS_UPLOAD = 'SHOW_METRICS_UUPLOAD',
    HIDE = 'HIDE',
}

export enum ModalTypes {
    SYSTEM = 'SYSTEM',
    CAPABILITY = 'CAPABILITY',
    ACTOR = 'ACTOR',
    GENERAL = 'GENERAL',
    INVITE_USER = 'INVITE_USER',
    ADD_LICENCE = 'ADD_LICENCE',
    ADD_COMPANY = 'ADD_COMPANY',
    UPLOAD_MODAL = 'UPLOAD_MODAL',
    EDIT_USER_MODAL = 'EDIT_USER_MODAL',
    EDIT_LICENSE_MODAL = 'EDIT_LICENSE_MODAL',
    CONTACT_SUPPORT = 'CONTACT_SUPPORT',
    METRICS_UPLOAD = 'METRICS_UPLOAD'
}