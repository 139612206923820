import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './index.css';

import { IAppState } from 'reducers';
import { IModel } from 'reducers/model';
import { getUsersList, getCompaniesList } from 'actions/admin';
import UsersListItem from 'components/users-list-item';
import { getUsersList as getUserListSelector, getUserAdminRoleStatus } from 'utils/selectors';
import useSortableData from 'hooks/useSortableData';
import TableHeader from 'components/table-header';
import { DataStore } from 'services/dataStoreInterfaces';
import { IUserInviteStatus } from 'services/firebase/interfaces';

export interface IUser extends DataStore.UserStoreDataWithId {
    groups: DataStore.UserGroupStoreData[];
    models: IModel[];
}

export default function UsersList() {
    const dispatch = useDispatch();
    const state = useSelector((state: IAppState) => state);
    const isUserSuperAdmin = getUserAdminRoleStatus(state);
    // const licences = useUsersSubscription(companiesList[0].id);
    const usersList = getUserListSelector(state);
    const [filter, setFilter] = useState('');
    const filteredUserList = useMemo(() => {
        const filterLowered = filter.toLowerCase();
        return usersList
            .map(user => ({
                ...user, 
                status: user.disabled ? IUserInviteStatus.DISABLED : user.status
            }))
            .filter(
                user =>
                    user.companyName?.toLowerCase().includes(filterLowered) ||
                    user.name?.toLowerCase().includes(filterLowered) ||
                    user.email.toLowerCase().includes(filterLowered) ||
                    user.status.toLowerCase()?.includes(filterLowered) ||
                    user.groups
                        .map(({ name }) => name.toLowerCase())
                        .join('')
                        .includes(filterLowered),
            )
            .map(user => ({ 
                ...user,
                group: user.groups[0]?.name 
            }));
    }, [usersList, filter]);
    const { items, requestSort, sortConfig } = useSortableData(filteredUserList);

    useEffect(() => {
        state.admin.companiesList.map(({ id }) => dispatch(getUsersList({ companyId: id })));
    }, [dispatch, state.admin.companiesList]);

    useEffect(() => {
        if (isUserSuperAdmin) {
            dispatch(getCompaniesList({}));
        } else {
            dispatch(getUsersList({ companyId: state.user.companyId }));
        }
    }, [dispatch, isUserSuperAdmin, state.user.companyId]);

    return (
        <div className="user-list-container">
            <div className="user-list-filter">
                <input
                    value={filter}
                    type="text"
                    onChange={e => setFilter(e.target.value)}
                    placeholder="Filter by : name, status, company, user group "
                />
            </div>
            <div className="user-list">
                <div></div>
                <TableHeader
                    name="email"
                    label="NAME"
                    sortConfig={sortConfig}
                    onClick={() => requestSort('email')}
                />
                <TableHeader
                    name="status"
                    label="STATUS"
                    sortConfig={sortConfig}
                    onClick={() => requestSort('status')}
                />
                <TableHeader
                    name="companyName"
                    label="COMPANY"
                    sortConfig={sortConfig}
                    onClick={() => requestSort('companyName')}
                />
                <TableHeader
                    name="groups"
                    label="USER GROUP"
                    sortConfig={sortConfig}
                    onClick={() => requestSort('group')}
                />
                <TableHeader label="MODEL" />
                <TableHeader label="ACTION" />
                {items.map(user => (
                    <UsersListItem
                        key={user.uid}
                        uid={user.uid}
                        name={user.name}
                        email={user.email}
                        status={user.status}
                        disabled={user.disabled}
                        companyName={user.companyName}
                        companyId={user.companyId}
                        userModels={user.models}
                        userGroups={user.groups}
                    />
                ))}
            </div>
            <div className="user-list-compact">
                <div className='header'>
                    <TableHeader
                        name="email"
                        label="NAME"
                        sortConfig={sortConfig}
                        onClick={() => requestSort('email')}
                    />
                    <TableHeader
                        name="status"
                        label="STATUS"
                        sortConfig={sortConfig}
                        onClick={() => requestSort('status')}
                    />
                    <TableHeader
                        name="companyName"
                        label="COMPANY"
                        sortConfig={sortConfig}
                        onClick={() => requestSort('companyName')}
                    />
                    <TableHeader
                        name="groups"
                        label="USER GROUP"
                        sortConfig={sortConfig}
                        onClick={() => requestSort('group')}
                    />
                    <TableHeader label="MODEL" />
                    <TableHeader label="ACTION" />
                </div>
                {items.map(user => (
                    <div className='user-list-row' key={user.uid}>
                        <UsersListItem
                            key={user.uid}
                            uid={user.uid}
                            name={user.name}
                            email={user.email}
                            status={user.status}
                            disabled={user.disabled}
                            companyName={user.companyName}
                            companyId={user.companyId}
                            userModels={user.models}
                            userGroups={user.groups}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
