import { SCENARIOS } from '../constants/scenarios';
import { IAction } from '../reducers';
import { IScenario, IScenarioStep } from '../interfaces';

export interface IOverlayAction {
    scenarioId: string
    state: boolean
}

export interface IReceivedScenariosPayload {
    scenarios: IScenario[]
}

export interface ISelectScenario {
    scenario: IScenario
    step: IScenarioStep
}

export interface IActionReceivedScenarios extends IAction<typeof SCENARIOS.RECEIVED_SCENARIOS, IReceivedScenariosPayload> { }
export interface IActionSelectScenario extends IAction<typeof SCENARIOS.SELECT_SCENARIO, ISelectScenario> { }
export interface IActionOverlayAllCapabilities extends IAction<typeof SCENARIOS.OVERLAY_ALL_CAPABILITIES, IOverlayAction> { }
export interface IActionResetSelection extends IAction<typeof SCENARIOS.RESET_SELECTION> { }

export const receivedScenarios = (scenarios: IScenario[]): IActionReceivedScenarios => ({
    type: SCENARIOS.RECEIVED_SCENARIOS,
    payload: { scenarios }
});

export const selectScenario = (scenario: IScenario, step: IScenarioStep): IActionSelectScenario => ({
    type: SCENARIOS.SELECT_SCENARIO,
    payload: { scenario, step }
});

export const overlayAllCapabilities = (payload: IOverlayAction): IActionOverlayAllCapabilities => ({
    type: SCENARIOS.OVERLAY_ALL_CAPABILITIES,
    payload
});

export const resetSelection = (): IActionResetSelection => ({
    type: SCENARIOS.RESET_SELECTION,
    payload: {}
});

export type IScenarioActions = IActionReceivedScenarios | IActionSelectScenario | IActionOverlayAllCapabilities | IActionResetSelection