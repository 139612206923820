import {GROUPS} from '../constants/action-types'
import { IAction } from '../reducers'
import { ActionStatus } from '../constants/action-statuses';

export interface IActionGetAllGroupsPayload {
    status: ActionStatus;
    response?: any;
}

export interface IActionGetAllModelsPayload {
    status: ActionStatus;
    response?: any;
}

export interface IActionGetAllGroups extends IAction<typeof GROUPS.GET_ALL_GROUPS, IActionGetAllGroupsPayload> { }
export interface IActionGetAllModels extends IAction<typeof GROUPS.GET_ALL_MODELS, IActionGetAllModelsPayload> { }

export const getAllGroups = (status: ActionStatus, response?: any):IActionGetAllGroups => ({
    type: GROUPS.GET_ALL_GROUPS,
    payload: {status, response}
})

export const getAllModels = (status: ActionStatus, response?: any):IActionGetAllModels => ({
    type: GROUPS.GET_ALL_MODELS,
    payload: {status, response}
})

export type IGroupsActions = IActionGetAllGroups | IActionGetAllModels;