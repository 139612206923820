import React from 'react';

import './index.css';

interface IMetricBarItemProps {
    name: string;
    outerColor: string;
    innerColor: string;
}

export default function MetricBarItem ({name, outerColor, innerColor}:IMetricBarItemProps) {
    return (
        <div className='metric-bar-item'>
            <div className='metric-bar-item_icon-container'
                style={{backgroundColor: outerColor}}>
                <div className='metric-bar-item_icon-inner-item'
                    style={{backgroundColor: innerColor}}/>
            </div>
            <div className='metric-bar-item_label'>{name}</div>
        </div>
    )
}
